import { Action } from '@ngrx/store';

export enum UserActionTypes {
  LoginUser = '[Login Page] User Login',
  LoginUserSuccess = '[Login Page] User Login Success',
  LoginUserFailed = '[Login Page] User Login Failure',

  LogoutUser = '[Login Page] User Logout',
  LogoutUserSuccess = '[Login Page] User Logout Success',
  LogoutUserFailed = '[Login Page] User Logout Failure',
}

export class LoginUser implements Action {
  readonly type = UserActionTypes.LoginUser;
}

export class LoginUserSuccess implements Action {
  readonly type = UserActionTypes.LoginUserSuccess;

  constructor(public payload?: any) {}
}

export class LoginUserFailed implements Action {
  readonly type = UserActionTypes.LoginUserFailed;

  constructor(public payload?: any) {}
}

export class LogoutUser implements Action {
  readonly type = UserActionTypes.LogoutUser;
  constructor(public payload: any) {}
}

export class LogoutUserSuccess implements Action {
  readonly type = UserActionTypes.LogoutUserSuccess;

  constructor(public payload?: any) {}
}

export class LogoutUserFailed implements Action {
  readonly type = UserActionTypes.LogoutUserFailed;

  constructor(public payload?: any) {}
}

export type LoginUserActions = LoginUser | LoginUserSuccess | LoginUserFailed | LogoutUser | LogoutUserSuccess | LogoutUserFailed;
