import { INotificationsFilterState, INotificationsState } from 'src/app/models';

import {
  NotificationActionTypes,
  NotificationsActions
} from '../actions/notification.actions';

const initialNotificationFilterState: INotificationsFilterState = {
  from: 0,
  size: 50,
  type: "",
  changeType: "",
  newRecordType: "",
  state: "Pending",
  sort: "",
  query: "",
};

const initialNotificationState: INotificationsState = {
  notifications: [],
  isLoadingNotifications: false,
  numNotifications: 0,
  totalNumPendingNotifications: 0,
  
  // Notification states
  states: [
    "Pending",
    "Acknowledged",
    "Rejected",
  ],

  // Notification types
  types: [
    "Attention",
    "Change",
    "Critical"
  ],

  // Entity types
  entityTypes: [
    "os",
    "vendor",
    "software"
  ],
  
  // Change types
  changeTypes: [
    "New",
    "Modified",
    "Removed"
  ],
};

export function notificationFilterReducer(
  state: any = initialNotificationFilterState,
  action: NotificationsActions
) {
  switch (action.type) {
    case NotificationActionTypes.FetchNotificationsFilter:
      return {
        ...state,
      };
    case NotificationActionTypes.UpdateNotificationType:
      return {
        ...state,
        type: action.payload.type,
      };
    case NotificationActionTypes.UpdateNotificationState:
      return {
        ...state,
        state: action.payload.state,
      };
    case NotificationActionTypes.UpdateNotificationChangeType:
      return {
        ...state,
        changeType: action.payload.changeType,
      };
    case NotificationActionTypes.UpdateNotificationEntityType:
      return {
        ...state,
        newRecordType: action.payload.newRecordType,
      };
    case NotificationActionTypes.UpdateNotificationSearchTerm:
      return {
        ...state,
        from: action.payload.from,
        size: action.payload.size,
        query: action.payload.query,
      };
    case NotificationActionTypes.UpdateNotificationSort:
      return {
        ...state,
        sort: action.payload.sort,
      };
    case NotificationActionTypes.UpdateNotificationPagination:
      return {
        ...state,
        from: action.payload.from,
        size: action.payload.size,
        sort: action.payload.sort,
      };
    case NotificationActionTypes.ResetNotificationsFilter:
      return {
        ...state,
        from: 0,
        size: 50,
        type: "",
        changeType: "",
        newRecordType: "",
        state: "",
        sort: "",
        query: "",
      };
    default:
      return state;
  }
}

export function notificationReducer(
  state: any = initialNotificationState,
  action: NotificationsActions
) {
  switch (action.type) {
    case NotificationActionTypes.FetchNotifications:
      return {
        ...state,
        isLoadingNotifications: true,
      };
    case NotificationActionTypes.FetchNotificationsSuccess:
      return {
        ...state,
        notifications: action.payload.notifications,
        numNotifications: action.payload.total,
        isLoadingNotifications: false
      };
    case NotificationActionTypes.FetchNotificationsFailed:
      return {
        ...state,
        isLoadingNotifications: false,
      };
    case NotificationActionTypes.FetchNotificationsCount:
      return {
        ...state,
      };
    case NotificationActionTypes.FetchNotificationsCountSuccess:
      return {
        ...state,
        totalNumPendingNotifications: action.payload.total,
      };
    case NotificationActionTypes.FetchNotificationsCountFailed:
      return {
        ...state,
      };
    case NotificationActionTypes.UpdateNotification:
      return {
        ...state,
        isLoadingNotifications: true,
      };
    case NotificationActionTypes.UpdateNotificationSuccess:
      return {
        ...state,
        isLoadingNotifications: false,
      };
    case NotificationActionTypes.UpdateNotificationFailed:
      return {
        ...state,
        isLoadingNotifications: false,
      };
    default:
      return state;
  }
}
