import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, LazyLoadEvent, PrimeNGConfig } from 'primeng/api';
import { AuthoritativeRecordsComponent } from '../authoritative.records/authoritative.records.component';
import {
  AuthoritativeRecordDetailsActionTypes,
  DeleteInputMappingRecord,
  FetchAuthoritativeRecordById,
  FetchInputMappingsByAuthoritativeRecordId,
  MoveInputRecord,
  ResetAuthoritativeRecordsFilter,
  UpdateAuthoritativeRecordDetailsPagination
} from 'src/app/store/actions';
import { Store } from '@ngrx/store';
import { getAuthoritativeRecordDetailsFilterState, getAuthoritativeRecordDetailsState } from 'src/app/store';
import { ActivatedRoute, Router } from '@angular/router';
import {
  IMetadata
} from 'src/app/models';
import { Actions, ofType } from '@ngrx/effects';
import { FetchLowConfidenceScoresCount } from 'src/app/store/actions/low.confidence.score.actions';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-authoritative.record.details',
  templateUrl: './authoritative.record.details.component.html',
  styleUrls: ['./authoritative.record.details.component.css'],
})
export class AuthoritativeRecordDetailsComponent implements OnInit {
  @ViewChild(AuthoritativeRecordsComponent) dashboardComponent: AuthoritativeRecordsComponent;
  inputRecords: any[] = [];
  metadataCount: number;
  totalInputRecords: number;
  metaData: IMetadata[];
  showMoveForm: boolean;
  isLoadingDetails: boolean = false;
  isLoadingInputRecords: boolean = false;
  selectedInputRecord: any;
  authoritativeRecordDetails: any;
  selectedRecord: any;
  selectedMetadata: any;
  searchTerm: string;
  messages: any[];
  isShowError: boolean = false;
  normalizedKeys: any[]
  constructor(
    private confirmService: ConfirmationService,
    private store: Store,
    private activeRoute: ActivatedRoute,
    private actions: Actions,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.authoritativeRecordDetails = {}
  }

  ngOnInit(): void {
    this.store.select(getAuthoritativeRecordDetailsState).subscribe((data) => {
      if (data) {
        this.isLoadingDetails = data.isLoadingDetails;
        this.authoritativeRecordDetails = data.authoritativeRecordDetails;
        this.inputRecords = data.inputRecords;
        this.isLoadingInputRecords = data.isLoadingInputRecords
        this.totalInputRecords = data.totalInputRecords

        if (this.authoritativeRecordDetails) {
          this.metaData = this.getFieldValues(this.authoritativeRecordDetails);
        }
      }
    })

    this.store.select(getAuthoritativeRecordDetailsFilterState).subscribe((data) => {
      if (data) {
        this.store.dispatch(new FetchInputMappingsByAuthoritativeRecordId({
          ...data,
          id: this.activeRoute.snapshot.paramMap.get('id')
        }));
      }
    });

    this.activeRoute.params.subscribe((params) => {
      this.metaData = null;
      this.authoritativeRecordDetails = null;
      this.store.dispatch(new FetchAuthoritativeRecordById(params.id));
    });
  }

  applyTableFilters(event: LazyLoadEvent) {
    this.store.dispatch(new UpdateAuthoritativeRecordDetailsPagination({ from: event.first, size: event.rows, sort: event.sortField !== null ? `${event.sortField}:${event.sortOrder === -1 ? "desc" : "asc"}` : '' }));
  }

  getTotalUnapprovedMappingsCount() {
    this.store.dispatch(new FetchLowConfidenceScoresCount());
    this.changeDetectorRef.detectChanges();
  }

  // function invoked when clicked on move input record button
  openMoveRecordForm(record: any) {
    this.showMoveForm = true;
    this.selectedInputRecord = record;
    const strArr: string[] = record.inputRecord.split(/\s+/);

    // take the keyword from the os name field and apply search on authoritative records list
    this.searchTerm = '';
    const searchStr: any = Object.values(strArr)[0];
    searchStr.split(' ').forEach((ss: any) => {
      const num = Number(ss);
      if (Number.isNaN(num)) {
        this.searchTerm = `${this.searchTerm} ${ss}`;
      }
    });
    this.searchTerm = this.searchTerm.trim();
  }

  saveOrUpdate() {
    if (this.dashboardComponent && this.dashboardComponent.selected) {
      this.moveInputRecordConfirm(this.selectedInputRecord);
    }
  }

  // Function invoked when button to confirm action move input record is clicked
  moveInputRecord(record: any) {
    const payload: any = {
      input_record_id: record.id,
      autht_record_id: this.dashboardComponent.selected.id,
    };

    this.store.dispatch(new MoveInputRecord(payload));

    // Subscribe to the move success action
    this.actions.pipe(
      ofType(AuthoritativeRecordDetailsActionTypes.MoveInputRecordSuccess),
      take(1)
    )
      .subscribe(() => {
        this.showMessage('success', 'Success', `Successfully moved input record`);
        this.dismissPanel();
        setTimeout(() => {
          this.store.select(getAuthoritativeRecordDetailsFilterState).subscribe((data) => {
            if (data) {
              this.store.dispatch(new FetchInputMappingsByAuthoritativeRecordId({
                ...data,
                id: this.activeRoute.snapshot.paramMap.get('id')
              }));
            }
          });
        }, 2000)
      });
  }

  // commpon function to reset panel
  dismissPanel() {
    setTimeout(() => {
      this.showMoveForm = false;
      this.resetAuthtFilters();
    }, 1500);
  }

  confirmDeleteInputRecord(inputRecord: any) {
    this.confirmService.confirm({
      icon: 'pi pi-info-circle',
      accept: () => {
        this.store.dispatch(new DeleteInputMappingRecord(inputRecord));

        // when input mapping deleted successfully
        this.actions
          .pipe(ofType(AuthoritativeRecordDetailsActionTypes.DeleteInputMappingSuccess))
          .subscribe((action: any) => {
            setTimeout(() => {
              this.showMessage('success', 'Success', `Successfully deleted input record`);
              this.store.select(getAuthoritativeRecordDetailsFilterState).subscribe((data) => {
                if (data) {
                  this.store.dispatch(new FetchInputMappingsByAuthoritativeRecordId({
                    ...data,
                    id: this.activeRoute.snapshot.paramMap.get('id')
                  }));
                }
              });
            }, 3000)
          });

        // when input mapping failed
        this.actions
          .pipe(ofType(AuthoritativeRecordDetailsActionTypes.DeleteInputMappingFailed))
          .subscribe((action: any) => {
            this.showMessage('error', 'Error', `Failed to move input record`);
          });

      },
      reject: () => { },
      key: 'deleteInputRecord',
    });
  }

  moveInputRecordConfirm(data: any) {
    this.confirmService.confirm({
      icon: 'pi pi-info-circle',
      accept: () => {
        this.moveInputRecord(data);
      },
      reject: () => { },
      key: 'moveInputRecord',
    });
  }

  listEnrichmentFields(entityType: string): Map<string, string[]> {
    const theMap = new Map<string, string[]>();
    switch (entityType) {
      case 'vendor':
        theMap.set('Enrichment', ['alias', 'parent']);
        theMap.set('Meta', ['vendor', 'source']);
        break;
      case 'os':
        theMap.set('Enrichment', ['baseReleaseDate', 'endOfLifeDate', 'endOfMaintenanceDate', 'endOfSupportDate', 'extendedEndOfMaintenanceDate',
          'extendedEndOfSupportDate', 'releaseStartDate', 'releaseEndDate']);
        theMap.set('Meta', ['vendor', 'name', 'version', 'family', 'akaName', 'akaVersion', 'source']);
        break;
      case 'software':
        theMap.set('Enrichment', ['baseReleaseDate', 'endOfLifeDate', 'endOfMaintenanceDate', 'endOfSupportDate', 'extendedEndOfMaintenanceDate',
          'extendedEndOfSupportDate', 'releaseStartDate', 'releaseEndDate', "category", "categoryGroup", "subCategory", "licensing", "licenseMetrics",
          'licenseModel', 'partOfASuite', 'softwareSuite', 'description']);
        theMap.set('Meta', ['vendor', 'name', 'version', 'family', 'akaName', 'akaVersion', 'source']);
        break;
      default:
        break;
    }
    return theMap;
  }

  resetAuthtFilters() {
    this.store.dispatch(new ResetAuthoritativeRecordsFilter());
  }

  getFieldValues(authtRecord: any): IMetadata[] {
    const result: IMetadata[] = [];
    const fullList = this.listEnrichmentFields(authtRecord.entityType);
    fullList.forEach((fields, fieldType) => {
      fields.forEach(field => {
        result.push({
          key: field,
          label: field,
          type: fieldType,
          value: authtRecord[field],
          isEdit: false
        });
      });
    })
    return result;
  }

  showMessage(severity: string, summary: string, detail: string) {
    this.messages = [
      {
        severity: severity,
        summary: summary,
        detail: detail,
      },
    ];
    setTimeout(() => {
      this.messages = [];
    }, 4000);
  }
}