import { Action } from '@ngrx/store';

export enum AuthoritativeRecordDetailsActionTypes {
  FetchAuthoritativeRecordById = '[Authoritative Record Details Page] Fetch Authoritative Record By Id',
  FetchAuthoritativeRecordByIdSuccess = '[Authoritative Record Details Page] Fetch Authoritative Record By Id Success',
  FetchAuthoritativeRecordByIdFailed = '[Authoritative Record Details Page] Fetch Authoritative Record By Id Failed',

  FetchInputMappingsByAuthoritativeRecordId = '[Authoritative Record Details Page] Fetch Input Mappings By Authoritative Record Id',
  FetchInputMappingsByAuthoritativeRecordIdSuccess = '[Authoritative Record Details Page] Fetch Input Mappings By Authoritative Record Id Success',
  FetchInputMappingsByAuthoritativeRecordIdFailed = '[Authoritative Record Details Page] Fetch Input Mappings By Authoritative Record Id Failed',

  DeleteInputMapping = '[Authoritative Record Details Page] Delete Input Record Mapping For Authoritative Record',
  DeleteInputMappingSuccess = '[Authoritative Record Details Page] Delete Input Record Mapping For Authoritative Record Success',
  DeleteInputMappingFailed = '[Authoritative Record Details Page] Delete Input Record Mapping For Authoritative Record Failed',

  MoveInputRecord = '[Authoritative Records Details Page] Move Input Record Mapping',
  MoveInputRecordSuccess = '[Authoritative Records Details Page] Move Input Record Mapping Success',
  MoveInputRecordFailed = '[Authoritative Records Details Page] Move Input Record Mapping Failed',

  UpdateAuthoritativeRecordDetailsPagination = '[Authoritative Record Details Page] Update Authoritative Record Details Pagination',
}

export class FetchAuthoritativeRecordById implements Action {
  readonly type = AuthoritativeRecordDetailsActionTypes.FetchAuthoritativeRecordById;
  constructor(public id: any) {}
}

export class FetchAuthoritativeRecordByIdSuccess implements Action {
  readonly type = AuthoritativeRecordDetailsActionTypes.FetchAuthoritativeRecordByIdSuccess;
  constructor(public payload: any) {}
}

export class FetchAuthoritativeRecordByIdFailed implements Action {
  readonly type = AuthoritativeRecordDetailsActionTypes.FetchAuthoritativeRecordByIdFailed;
  constructor(public payload: any) {}
}

export class FetchInputMappingsByAuthoritativeRecordId implements Action {
  readonly type = AuthoritativeRecordDetailsActionTypes.FetchInputMappingsByAuthoritativeRecordId;
  constructor(public payload: any) {}
}

export class FetchInputMappingsByAuthoritativeRecordIdSuccess implements Action {
  readonly type = AuthoritativeRecordDetailsActionTypes.FetchInputMappingsByAuthoritativeRecordIdSuccess;
  constructor(public payload: any) {}
}

export class FetchInputMappingsByAuthoritativeRecordIdFailed implements Action {
  readonly type = AuthoritativeRecordDetailsActionTypes.FetchInputMappingsByAuthoritativeRecordIdFailed;
  constructor(public payload: any) {}
}

export class UpdateAuthoritativeRecordDetailsPagination implements Action {
  readonly type = AuthoritativeRecordDetailsActionTypes.UpdateAuthoritativeRecordDetailsPagination;
  constructor(public payload: any) {}
}

export class DeleteInputMappingRecord implements Action {
  readonly type = AuthoritativeRecordDetailsActionTypes.DeleteInputMapping;
  constructor(public payload: any) {}
}

export class DeleteInputMappingRecordSuccess implements Action {
  readonly type = AuthoritativeRecordDetailsActionTypes.DeleteInputMappingSuccess;
  constructor(public payload: any) {}
}

export class DeleteInputMappingRecordFailed implements Action {
  readonly type = AuthoritativeRecordDetailsActionTypes.DeleteInputMappingFailed;

  constructor(public payload: any) {}
}

export class MoveInputRecord implements Action {
  readonly type = AuthoritativeRecordDetailsActionTypes.MoveInputRecord;
  constructor(public payload: any) { }
}

export class MoveInputRecordSuccess implements Action {
  readonly type = AuthoritativeRecordDetailsActionTypes.MoveInputRecordSuccess;
  constructor(public payload: any) { }
}

export class MoveInputRecordFailed implements Action {
  readonly type = AuthoritativeRecordDetailsActionTypes.MoveInputRecordFailed;
  constructor(public payload: any) { }
}

export type AuthoritativeRecordDetailsActions =
  | FetchAuthoritativeRecordById
  | FetchAuthoritativeRecordByIdSuccess
  | FetchAuthoritativeRecordByIdFailed
  | FetchInputMappingsByAuthoritativeRecordId
  | FetchInputMappingsByAuthoritativeRecordIdSuccess
  | FetchInputMappingsByAuthoritativeRecordIdFailed
  | UpdateAuthoritativeRecordDetailsPagination
  | DeleteInputMappingRecord
  | DeleteInputMappingRecordSuccess
  | DeleteInputMappingRecordFailed
  | MoveInputRecord
  | MoveInputRecordSuccess
  | MoveInputRecordFailed;
