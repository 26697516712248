import { Injectable } from '@angular/core';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import {
    GetAppVersionData,
    GetAppVersionDataSuccess,
    GetAppVersionDataFailed,
    CommonActionTypes,
} from '../actions';

@Injectable()
export class CommonEffects {
  constructor(private actions$: Actions, private authService: AuthService) {}

  // Login Effect
  @Effect()
  appData$ = this.actions$.pipe(
    ofType(CommonActionTypes.GetAppVersionData),
    switchMap((action: GetAppVersionData) =>
      this.authService.fetchAppData().pipe(
        map((data: any) => new GetAppVersionDataSuccess(data)),
        catchError(error => of(new GetAppVersionDataFailed({ message: error.error ? error.error : error.message })))
      )
    )
  );
}
