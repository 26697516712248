import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';

const routes: Routes = [
  { path: '', redirectTo: '/authoritativerecords', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'authoritativerecords', loadChildren: () => import('./components/authoritative.records/authoritative.records.module').then(m => m.DashboardModule)},
  { path: 'notifications', loadChildren: () => import('./components/notifications/notifications.module').then(m => m.NotificationsModule) },
  { path: 'notification/:id', loadChildren: () => import('./components/notifications/notification-details/notification-details.module').then(m => m.NotificationDetailsModule) },
  { path: 'suggestions', loadChildren: () => import('./components/suggestions/suggestions.module').then(m => m.SuggestionsModule)},
  { path: 'confidencescores', loadChildren: () => import('./components/low.confidence.score/low.confidence.score.module').then(m => m.LowConfidenceScoreModule)},
  { path: 'importexport', loadChildren: () => import('./components/import.export/import.export.module').then(m => m.ImportExportModule)},
  { path: 'sync', loadChildren: () => import('./components/synchronize/synchronize.module').then(m => m.SynchronizeModule)},
  { path: 'settings', loadChildren: () => import('./components/settings/settings.module').then(m => m.SettingsModule) },
  { path: '**', redirectTo: '/authoritativerecords'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
