export const app_environment = {
  production: false,
  service: {
    apiStage: 'v1',
  },
  firebaseConfig: getConfig()
};

function getConfig() {
    let config;
    let request = new XMLHttpRequest();
    try {
		const domain = window.location.hostname;
		var baseUrl = `https://${domain}`;
		if (domain == "localhost") {
			const port = window.location.port;
			baseUrl = `http://${domain}:${port}`;
		}
		
		const url = baseUrl+'/firebaseconfig.json?_=' + new Date().getTime()
		request.open('GET', url , false);
        request.send(null);

        if (request.status === 200) {
            config = request.responseText;
			return JSON.parse(config);
        }
		console.log('environment:getConfig: unable to get api key : ', request.status, request.responseText);
    } catch (e) {
        console.error('environment:getConfig: unable to get api key : ', e);
    }
    return config;
}