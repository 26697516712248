import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { saveAs } from 'file-saver';
import {
  ExportFile,
  FetchUploadStatus,
  FetchUploadUrl,
  ImportExportActionTypes,
} from 'src/app/store/actions/import.export.actions';
import { getImportExportData } from 'src/app/store';
import { MessageService } from 'primeng/api';
import { Actions, ofType } from '@ngrx/effects';
import { FormBuilder, Validators } from '@angular/forms';
import { IImportExportState } from 'src/app/models';

@Component({
  selector: 'app-import.export',
  templateUrl: './import.export.component.html',
  styleUrls: ['./import.export.component.css'],
})
export class ImportExportComponent implements OnInit {
  password: string;
  selectedFile: any;
  textType: string;
  messages: any[];
  isShowError: boolean = false;
  error: string;
  oldStatus: string;
  importExportForm: any;
  isInProgress: boolean;
  stateData: IImportExportState;
  constructor(
    private store: Store,
    private messageService: MessageService,
    private actions: Actions,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.initializeForm();
    this.textType = 'password'; // set field as 'password' type by default
    this.store.select(getImportExportData).subscribe((data) => {
      if (data) {
        this.stateData = data;
        this.isInProgress = data.isImportExportInProgress;
        // on state change if dowload link is there then download file
        if (data && data.exportData && data.exportData.download_link) {
          saveAs(data.exportData.download_link, data.exportData.file_name);
        }
        this.isShowError = false;
        // show error message if action failed
        if (data && data.errorMessage) {
          this.isShowError = true;
          this.error = data.errorMessage;
        }
      }
    });

    this.actions
      .pipe(ofType(ImportExportActionTypes.FetchUploadStatusSuccess))
      .subscribe((pdata: any) => {
        let data = this.stateData;
        if (data && data.uploadStatus) {
          let msg = {};
          if (data.uploadStatus.toLowerCase() === 'success') {
            msg = {
              severity: 'success',
              summary: 'Info',
              detail: data.uploadStatus,
            };
          } else {
            msg = {
              severity: 'info',
              summary: 'Info',
              detail: data.uploadStatus,
            };
          }
          this.messages = [msg];
          if (
            data &&
            data.req_id &&
            (data.uploadStatus.toLowerCase().includes('progress') ||
              data.uploadStatus.toLowerCase().includes('restoring'))
          ) {
            setTimeout(() => {
              this.store.dispatch(new FetchUploadStatus(data));
            }, 4000);
          } else {
            this.clearMessages();
          }
        }
      });
  }

  initializeForm() {
    this.importExportForm = this.fb.group({
      password: ['', Validators.required, Validators.minLength],
    });
  }

  // removes error popup showing on the screen
  closeError() {
    this.error = null;
    this.isShowError = false;
  }

  changeTextType(type: string) {
    this.textType = type;
  }

  // clear messages from screen
  clearMessages() {
    setTimeout(() => {
      this.messages = [];
    }, 4000);
  }

  // function invoked on Export action
  exportFile() {
    this.store.dispatch(new ExportFile({ password: this.password }));
  }

  // function invoked when a file selected to upload
  onFileSelected(e: any) {
    this.selectedFile = e.target.files[0];
    const payload = {
      file: this.selectedFile,
      password: this.password,
    };
    this.store.dispatch(new FetchUploadUrl(payload));
  }
}
