import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class SettingsService extends CommonService {
  constructor(private http: HttpClient, public loc: Location) {
    super(loc);
  }

  sync() {
    return from(
      this.http
        .put<any>(
          `${this.baseUrl}/${this.environment.service.apiStage}/opensearch/sync`,
          {}
        )
        .pipe(
          catchError(this.handleError)
        )
    );
  }

  remap() {
    return from(
      this.http
        .put<any>(
          `${this.baseUrl}/${this.environment.service.apiStage}/remap`,
          {}
        )
        .pipe(
          catchError(this.handleError)
        )
    );
  }

  checkRemapStatus() {
    return from(
      this.http
        .get<any>(
          `${this.baseUrl}/${this.environment.service.apiStage}/remap/status`
        )
        .pipe(
          map((response) => response),
          catchError(this.handleError)
        )
    );
  }

  getSyncStatus() {
    return from(
      this.http
        .get<any>(
          `${this.baseUrl}/${this.environment.service.apiStage}/opensearch/sync/status`
        )
        .pipe(
          map((response) => response),
          catchError(this.handleError)
        )
    );
  }

}
