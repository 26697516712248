import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  ConfirmationService,
  LazyLoadEvent
} from 'primeng/api';
import { Subscription } from 'rxjs';
import {
  ILowConfidenceScore
} from 'src/app/models';
import {
  getLowConfidenceScoreDetailsFilterState, 
  getLowConfidenceScoreDetailsState } from 'src/app/store';
import {
  AuthoritativeRecordDetailsActionTypes,
  DeleteInputMappingRecord,
  MoveInputRecord,
  ResetAuthoritativeRecordsFilter,
} from 'src/app/store/actions';
import {
  ApproveInputRecordMapping,
  FetchLowConfidenceScoresCount,
  LowConfidenceScoreActionTypes,
  UpdateLowConfidenceScore,
} from 'src/app/store/actions/low.confidence.score.actions';
import { AuthoritativeRecordsComponent } from '../authoritative.records/authoritative.records.component';
import { take } from 'rxjs/operators';
import { 
  FetchLowConfidenceAuthoritativeRecords, 
  FetchLowConfidenceScoreById, 
  LowConfidenceScoreDetailsActionTypes, 
  ResetLowConfidenceScoreDetailsFilters, 
  UpdateLowConfidenceDetailsPagination, 
  UpdateLowConfidenceDetailsSearchQuery, 
  UpdateLowConfidenceScoreAuthtRecords 
} from 'src/app/store/actions/low.confidence.score.details.actions';

@Component({
  selector: 'app-low.confidence.score.details',
  templateUrl: './low.confidence.score.details.component.html',
  styleUrls: ['./low.confidence.score.details.component.css'],
})
export class LowConfidenceScoreDetailsComponent implements OnInit {
  @ViewChild(AuthoritativeRecordsComponent) dashboardComponent: AuthoritativeRecordsComponent;
  
  moveSubscription: Subscription;
  deleteSubscription: Subscription;
  inputRecordDetails: ILowConfidenceScore;
  totalRecordCount: number;
  searchTerm: string;
  first: any;
  rows: any;
  size: number = 50;
  matchingAuthoritativeRecords: any[];
  showMoveForm: boolean;
  selectedAuthoritativeRecord: any;
  messages: any[];
  error: string;
  isLoading: boolean;
  constructor(
    private store: Store,
    private confirmService: ConfirmationService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private actions: Actions,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.activeRoute.params.subscribe((params) => {
      this.store.dispatch(new ResetLowConfidenceScoreDetailsFilters())
      this.store.dispatch(new FetchLowConfidenceScoreById(params.id));
      this.actions.pipe(
        ofType(LowConfidenceScoreDetailsActionTypes.FetchLowConfidenceScoreByIdSuccess),
        take(1)
      ).subscribe(() => {
          this.store.dispatch(new UpdateLowConfidenceScoreAuthtRecords(this.inputRecordDetails.unapprovedAuthtMappings))
        });
    });
    
    this.store.select(getLowConfidenceScoreDetailsState).subscribe((data) => {
      if (data) {
        this.isLoading = data.isLoading;
        this.inputRecordDetails = data.selectedLowConfidenceScore;
        this.matchingAuthoritativeRecords = data.authoritativeRecords;
        if (this.inputRecordDetails) {
          this.totalRecordCount = this.inputRecordDetails.unapprovedAuthtCount;
        }

        this.store.dispatch(new FetchLowConfidenceScoresCount());
      }
    })

    this.store.select(getLowConfidenceScoreDetailsFilterState).subscribe((data) => {
      if (data) {
        this.first = data.from;
        this.rows = data.size;
        if (data.authoritativeRecordIds.length > 0) {
          this.store.dispatch(new FetchLowConfidenceAuthoritativeRecords(data))
        } else {
          this.matchingAuthoritativeRecords = [];
        }
      }
    });
  }

  ngAfterViewInit(){
    this.changeDetectorRef.detectChanges();
  }

  openMoveForm() {
    const strArr = this.inputRecordDetails.processedInputRecord.split(' ');
    this.searchTerm = strArr.find(word => !/^\d+$/.test(word)) || '';
    this.showMoveForm = true;
  }

  saveOrUpdate() {
    if (this.dashboardComponent && this.dashboardComponent.selected) {
      this.moveInputRecordConfirm(this.dashboardComponent.selected);
    }
  }

  moveInputRecordConfirm(data: any) {
    this.selectedAuthoritativeRecord = data;
    setTimeout(() => {
      this.confirmService.confirm({
        icon: 'pi pi-info-circle',
        accept: () => {
          this.clearMessages();
          this.resetAuthtFilters();
          const payload = {
            input_record_id: this.inputRecordDetails.id,
            autht_record_id: data.id,
            ignored: false
          };

          this.store.dispatch(new MoveInputRecord(payload));

          // when input mapping moved successfully
          this.moveSubscription =  this.actions
            .pipe(ofType(AuthoritativeRecordDetailsActionTypes.MoveInputRecordSuccess))
            .subscribe((data: any) => {
              this.dismissPanel();
              this.showMessage('success', 'Success', 'Input record moved successfully');
              this.resetAuthtFilters();
              this.gotoLowConfidenceScoreList();
            });

          // when input mapping move is failed
          this.actions
            .pipe(ofType(AuthoritativeRecordDetailsActionTypes.MoveInputRecordFailed))
            .subscribe((data: any) => {
              this.dismissPanel();
              this.resetAuthtFilters();
              this.showMessage('error', 'Error', 'Input record move failed')
            });
        },
        reject: () => {
          this.selectedAuthoritativeRecord = undefined;
        },
        key: 'moveMapping',
      });
    }, 500);
  }

  // Function invoked when approve mapping action is confirmed by user
  approveInputRecordMapping(record: any) {
    this.clearMessages();
    const payload = {
      input_record_id: this.inputRecordDetails.id,
      autht_record_id: record.id,
      ignored: false
    };

    this.store.dispatch(new ApproveInputRecordMapping(payload));

    // when mapping approval is successful
    this.actions
      .pipe(ofType(LowConfidenceScoreActionTypes.ApproveMappingSuccess))
      .subscribe((data: any) => {
        this.showMessage('success', 'Success', 'Input record approved successfully');
        this.gotoLowConfidenceScoreList();
        this.showMoveForm = false;
      });

    // when mapping approval is failed
    this.actions
      .pipe(ofType(LowConfidenceScoreActionTypes.ApproveMappingFailed))
      .subscribe((data: any) => {
        this.showMessage('error', 'Error', 'Failed to approve input record');
        this.showMoveForm = false;
      });
  }

  showMessage(severity: string, summary: string, detail: string) {
    this.messages = [
      {
        severity: severity,
        summary: summary,
        detail: detail,
      },
    ];
    this.resetMessages();
  }

  resetMessages() {
    setTimeout(() => {
      this.messages = [];
    }, 4000);
  }
  
  clearMessages() {
    this.messages = [];
  }

  // on delete input record action
  confirmDeleteInputRecord() {
    this.confirmService.confirm({
      icon: 'pi pi-info-circle',
      accept: () => {
        let payload = {
          id: this.inputRecordDetails.id,
        };

        this.clearMessages();

        this.store.dispatch(new DeleteInputMappingRecord(payload));
        
        // when mapping approval is successful
        this.actions
        .pipe(ofType(AuthoritativeRecordDetailsActionTypes.DeleteInputMappingSuccess))
        .subscribe((data: any) => {
          this.showMessage('success', 'Success', 'Input record deleted successfully');
          this.gotoLowConfidenceScoreList();
          this.showMoveForm = false;
        });

      // when mapping approval is failed
      this.actions
        .pipe(ofType(AuthoritativeRecordDetailsActionTypes.DeleteInputMappingFailed))
        .subscribe((data: any) => {
          this.showMessage('error', 'Error', 'Input record deletion failed');
          this.showMoveForm = false;
        });
      },
      reject: () => {},
      key: 'deleteRecord',
    });
  }

  applyTableFilters(event: LazyLoadEvent) {
    this.store.dispatch(new UpdateLowConfidenceDetailsPagination({ from: event.first, size: event.rows, sort: event.sortField !== null ? `${event.sortField}:${event.sortOrder === -1 ? "desc" : "asc"}` : '' }));
  }

  applySearchTerm(searchTerm: string) {
    this.searchTerm = searchTerm;
    this.store.dispatch(new UpdateLowConfidenceDetailsSearchQuery({ from: 0, size: this.size, query: searchTerm }));
  }

  // navigate to Low Confidence Score list
  gotoLowConfidenceScoreList() {
    setTimeout(() => {
      this.router.navigate(['/confidencescores'], { replaceUrl: true });
    }, 2000);
  }

  resetAuthtFilters() {
    this.store.dispatch(new ResetAuthoritativeRecordsFilter());
  }

  // commpon function to close panel
  dismissPanel() {
    this.showMoveForm = false;
  }

  // on click of approve input record mapping
  approveInputRecordConfirm(data: any) {
    this.selectedAuthoritativeRecord = data;
    setTimeout(() => {
      this.confirmService.confirm({
        icon: 'pi pi-info-circle',
        accept: () => {
          this.approveInputRecordMapping(data);
        },
        reject: () => {
          this.selectedAuthoritativeRecord = undefined;
        },
        key: 'approveMapping',
      });
    }, 500);
  }

  makeActive() {
    this.store.dispatch(new UpdateLowConfidenceScore({ mappingId: this.inputRecordDetails.id, normalizationMappingUpdate: { ignored: false, updatedBy: JSON.parse(localStorage.getItem('user')).displayName } }));
    this.actions
      .pipe(ofType(LowConfidenceScoreActionTypes.UpdateLowConfidenceScoreSuccess))
      .subscribe((data: any) => {
        this.showMessage('success', 'Success', `Mapping is now active`);
        this.inputRecordDetails.ignored = false;
      });
    
    this.actions
      .pipe(ofType(LowConfidenceScoreActionTypes.UpdateLowConfidenceScoresManyFailed))
      .subscribe((data: any) => {
        this.showMessage('error', 'Error', 'Failed to make mapping active');
      });
  }

  ignore() {
    this.store.dispatch(new UpdateLowConfidenceScore({ mappingId: this.inputRecordDetails.id, normalizationMappingUpdate: { ignored: true, updatedBy: JSON.parse(localStorage.getItem('user')).displayName } }));
    this.actions
      .pipe(ofType(LowConfidenceScoreActionTypes.UpdateLowConfidenceScoreSuccess))
      .subscribe((data: any) => {
        this.showMessage('success', 'Success', `Mapping is now ignored`);
        this.inputRecordDetails.ignored = true;
      });

    this.actions
      .pipe(ofType(LowConfidenceScoreActionTypes.UpdateLowConfidenceScoreFailed))
      .subscribe((data: any) => {
        this.showMessage('error', 'Error', 'Failed to ignore mapping');
      });
  }

}
