import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { getSyncData } from 'src/app/store';
import { FetchSynchronizeStatus, SynchronizeData } from 'src/app/store/actions';

@Component({
  selector: 'app-synchronize',
  templateUrl: './synchronize.component.html',
  styleUrls: ['./synchronize.component.css'],
})
export class SynchronizeComponent implements OnInit {
  subscription: Subscription;
  isSyncInProgress: boolean;
  isSyncCompleted: boolean;
  isShowError: boolean;
  isSyncStarted: boolean = false; // variable used to redirect to home page after sync is done
  isLoadingStatus: boolean;
  error: string;
  constructor(
    private store: Store,
    private router: Router,
    private confirmService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new FetchSynchronizeStatus({ loading: true }));
    this.subscription = this.store.select(getSyncData).subscribe((data) => {
      if (data) {
        this.isSyncInProgress = data.syncInProgress;
        this.isSyncCompleted = data.isDone;
        this.isLoadingStatus = data.isLoading;
        setTimeout(() => {
          if (this.isSyncInProgress && !this.isSyncCompleted) {
            this.store.dispatch(
              new FetchSynchronizeStatus({
                loading: false,
                isInProgress: this.isSyncInProgress,
              })
            );
          }
        }, 3000);

        if (this.isSyncCompleted && this.isSyncStarted) {
          this.isSyncStarted = false;
          // if synchronization is completed then navigate back to home
          setTimeout(() => {
            this.router.navigate(['/authoritativerecords'], { replaceUrl: true });
          }, 3000);
        }

        this.isShowError = false;
        if (data.errorMessage) {
          this.isShowError = true;
          this.error = data.errorMessage;
        }
      }
    });
  }

  // synchronize
  syncData() {
    this.isSyncStarted = true;
    this.store.dispatch(new SynchronizeData());
  }

  // removes error popup showing on the screen
  closeError() {
    this.error = null;
    this.isShowError = false;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  syncAgainConfirm() {
    this.confirmService.confirm({
      icon: 'pi pi-info-circle',
      accept: () => {
        this.syncData();
      },
      reject: () => {},
      key: 'synchronizeData',
    });
  }
}
