import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { app_environment } from 'src/app.environment';

@Injectable()
export class CommonService {
  baseUrl: string;
  environment:any;
  constructor(public loc: Location) {
    const domain = window.location.hostname;
    this.baseUrl = `https://${domain}`;
    this.environment = app_environment;
  }

  //Error handler function
  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
