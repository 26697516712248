import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';

// prime ng related imports
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LocalizeComponent } from '../localize/localize.component';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NavigationComponent } from './navigation/navigation.component';
import { TabMenuModule } from 'primeng/tabmenu';


export function HttpLoaderFactory(http:HttpClient) {
  return new TranslateHttpLoader(http, '../../assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    HeaderComponent, 
    FooterComponent,
    LocalizeComponent,
    NavigationComponent
  ],
  imports: [
    CommonModule,
    AvatarModule,
    AvatarGroupModule,
    SharedModule,
    ButtonModule,
    DropdownModule,
    FormsModule, 
    ReactiveFormsModule,
    TabMenuModule,
    TranslateModule.forChild({
      loader:{
        provide:TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps:[HttpClient]
      },
      isolate: false
    })
  ],
  exports: [
    HeaderComponent, 
    FooterComponent,
    TranslateModule,
    LocalizeComponent,
    NavigationComponent
  ]
})
export class LayoutModule {}
