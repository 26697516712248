import { ISynchronizeState } from 'src/app/models';
import { SynchronizeActionTypes, SynchronizeActions } from '../actions';

const initialState: ISynchronizeState = {
  notification: null,
  errorMessage: null,
  syncInProgress: false,
  isLoading: false,
  isDone: false,
};

export function synchronizeReducer(
  state: ISynchronizeState = initialState,
  action: SynchronizeActions
) {
  switch (action.type) {
    case SynchronizeActionTypes.SynchronizeData:
      return {
        ...state,
        notification: null,
        errorMessage: null,
        isLoading: false,
        isDone: false,
        syncInProgress: true,
      };
    case SynchronizeActionTypes.SynchronizeDataSuccess:
      return { ...state };
    case SynchronizeActionTypes.SynchronizeDataFailed:
      return { ...state };
    case SynchronizeActionTypes.FetchSynchronizeStatus:
      return {
        ...state,
        isLoading: action.payload.loading,
        syncInProgress: action.payload.isInProgress,
        isDone: false
      };
    case SynchronizeActionTypes.FetchSynchronizeStatusSuccess:
      let isInProgress = false;
      let isdone = false;
      let error:string = null;
      if (
        action.payload.status &&
        action.payload.status.toLowerCase().includes('in progress')
      ) {
        isInProgress = true;
      }  else if (
        action.payload.status &&
        action.payload.status.toLowerCase().includes('success')
      ) {
        isdone = true;
      }
      return {
        ...state,
        notification: action.payload.status,
        syncInProgress: isInProgress,
        isDone: isdone,
        isLoading: false,
        errorMessage: error,
      };
    case SynchronizeActionTypes.FetchSynchronizeStatusFailed:
      return {
        ...state,
        isSyncInProgress:false,
        errorMessage: action.payload.message,
        isLoading: false,
      };
    default:
      return state;
  }
}
