import { Action } from '@ngrx/store';

export enum ImportExportActionTypes {
  ExportFile = "[Import Export Page] Export File",
  ExportFileSuccess = "[Import Export Page] Export File Success",
  ExportFileFailed = "[Import Export Page] Export File Failed",

  FetchUploadUrl = "[Import Export Page] Fetch Upload URL",
  FetchUploadUrlSuccess = "[Import Export Page] Fetch Upload URL Success",
  FetchUploadUrlFailed = "[Import Export Page] Fetch Upload URL Failed",

  UploadFile = "[Import Export Page] Upload File",
  UploadFileSuccess = "[Import Export Page] Upload File Success",
  UploadFileFailed = "[Import Export Page] Upload File Failed",

  ImportData = "[Import Export Page] Emport Data",
  ImportDataSuccess = "[Import Export Page] Emport Data Success",
  ImportDataFailed = "[Import Export Page] Emport Data Failed",

  FetchUploadStatus = "[Import Export Page] Fetch Upload File Status",
  FetchUploadStatusSuccess = "[Import Export Page] Fetch Upload File Status Success",
  FetchUploadStatusFailed = "[Import Export Page] Fetch Upload File Status Failed",
}

export class ExportFile implements Action {
  readonly type = ImportExportActionTypes.ExportFile;
  constructor(public payload: any) {}
}

export class ExportFileSuccess implements Action {
  readonly type = ImportExportActionTypes.ExportFileSuccess;
  constructor(public payload: any) {}
}

export class ExportFileFailed implements Action {
  readonly type = ImportExportActionTypes.ExportFileFailed;
  constructor(public payload: any) {}
}

export class FetchUploadUrl implements Action {
  readonly type = ImportExportActionTypes.FetchUploadUrl;
  constructor(public payload: any) {}
}

export class FetchUploadUrlSuccess implements Action {
  readonly type = ImportExportActionTypes.FetchUploadUrlSuccess;
  constructor(public payload: any) {}
}

export class FetchUploadUrlFailed implements Action {
  readonly type = ImportExportActionTypes.FetchUploadUrlFailed;
  constructor(public payload: any) {}
}

export class UploadFile implements Action {
  readonly type = ImportExportActionTypes.UploadFile;
  constructor(public payload: any) {}
}

export class UploadFileSuccess implements Action {
  readonly type = ImportExportActionTypes.UploadFileSuccess;
  constructor(public payload: any) {}
}

export class UploadFileFailed implements Action {
  readonly type = ImportExportActionTypes.UploadFileFailed;
  constructor(public payload: any) {}
}

export class ImportData implements Action {
  readonly type = ImportExportActionTypes.ImportData;
  constructor(public payload: any) {}
}

export class ImportDataSuccess implements Action {
  readonly type = ImportExportActionTypes.ImportDataSuccess;
  constructor(public payload: any) {}
}

export class ImportDataFailed implements Action {
  readonly type = ImportExportActionTypes.ImportDataFailed;
  constructor(public payload: any) {}
}

export class FetchUploadStatus implements Action {
  readonly type = ImportExportActionTypes.FetchUploadStatus;
  constructor(public payload: any) {}
}

export class FetchUploadStatusSuccess implements Action {
  readonly type = ImportExportActionTypes.FetchUploadStatusSuccess;
  constructor(public payload: any) {}
}

export class FetchUploadStatusFailed implements Action {
  readonly type = ImportExportActionTypes.FetchUploadStatusFailed;
  constructor(public payload: any) {}
}


export type ImportExportActions =
  | ExportFile
  | ExportFileSuccess
  | ExportFileFailed
  | FetchUploadUrl
  | FetchUploadUrlSuccess
  | FetchUploadUrlFailed
  | UploadFile
  | UploadFileSuccess
  | UploadFileFailed
  | ImportData
  | ImportDataSuccess
  | ImportDataFailed
  | FetchUploadStatus
  | FetchUploadStatusSuccess
  | FetchUploadStatusFailed;
