import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  FetchAuthoritativeRecords,
  FetchNotificationsCount,
  ResetAuthoritativeRecordsFilter,
  UpdateAuthoritativeRecordEntityType,
  UpdateAuthoritativeRecordPagination,
  UpdateAuthoritativeRecordSearchTerm,
} from 'src/app/store/actions';
import { getAuthoritativeRecordsFilterState, getAuthoritativeRecordsState } from 'src/app/store';
import { LazyLoadEvent } from 'primeng/api';
import { FetchLowConfidenceScoresCount } from 'src/app/store/actions/low.confidence.score.actions';
import { MultiSelect } from 'primeng/multiselect';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-authoritative-records',
  templateUrl: './authoritative.records.component.html',
  styleUrls: ['./authoritative.records.component.css'],
})
export class AuthoritativeRecordsComponent implements OnInit {
  @Input() searchtext: string = null;
  @Input() isInDialog: boolean = false;
  @Input() searchEntityType: string = null;

  @ViewChild('entityTypesMultiSelect') entityTypesMultiSelect: MultiSelect;
  @ViewChild('dt') dataTable: Table;

  records: any[] = [];
  selectedEntityTypes: any[] = [];
  totalRecordCount: number;
  isLoading: boolean = false;
  searchTerm: string;
  selected: any;
  first: any;
  rows: any;
  size: number = 50;
  sortField: string;
  sortOrder: number;
  selectedTypes: any[];
  entityTypes: any[] = [];

  constructor(
    private store: Store,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.store.select(getAuthoritativeRecordsState).subscribe((data) => {
      if (data) {
        this.records = data.authoritativeRecords;
        this.isLoading = data.isLoading;
        this.totalRecordCount = data.totalRecordCount
        this.entityTypes = data.entityTypes.map((entityType: string) => { return { entityType: entityType }});
      }
    })

    this.store.select(getAuthoritativeRecordsFilterState).subscribe((data) => {
      if (data) {
        this.selectedEntityTypes = data.entityType === '' ? null : data.entityType.split(',').map((entityType: string) => { return { entityType: entityType }});
        this.first = data.from;
        this.rows = data.size;
        this.sortField = data.sort.split(':')[0];
        this.sortOrder = data.sort.split(':')[1] === 'asc' ? 1 : -1;

        if (this.searchtext !== null && this.searchEntityType !== null) {
          this.searchTerm = this.searchtext;
          data.query = this.searchtext;
          data.entityType = this.searchEntityType;
          this.searchtext = null;
          this.searchEntityType = null;
        } else {
          this.searchTerm = data.query;
        }

        this.store.dispatch(new FetchAuthoritativeRecords(data));
        this.store.dispatch(new FetchLowConfidenceScoresCount());
        this.store.dispatch(new FetchNotificationsCount());
      }
    });
  }

  hasLength(data: any) {
    if (data) {
      return data.length > 0;
    }
    return false;
  }

  applySearchTerm(searchTerm: string) {
    this.searchTerm = searchTerm;
    this.store.dispatch(new UpdateAuthoritativeRecordSearchTerm({ from: 0, size: this.size, query: searchTerm }));
  }

  applyTableFilters(event: LazyLoadEvent) {
    this.store.dispatch(new UpdateAuthoritativeRecordPagination({ from: event.first, size: event.rows, sort: event.sortField !== null ? `${event.sortField}:${event.sortOrder === -1 ? "desc" : "asc"}` : '' }));
  }

  setEntityTypes(filters: any[]) {
    this.selectedEntityTypes = filters;
  }

  resetFilters() {
    this.dataTable.reset();
    this.store.dispatch(new ResetAuthoritativeRecordsFilter());
  }

  applyEntityTypes() {
    this.store.dispatch(new UpdateAuthoritativeRecordEntityType({ entityType: this.selectedEntityTypes.map((filter: any) => filter.entityType).join(',') }));
    this.entityTypesMultiSelect.hide();
  }

  showAuthoritativeRecordDetails(record: any) {
    if (!this.isInDialog) {
      this.router.navigate([
        `/authoritativerecords/${record.id}`,
      ]);
    } else {
      if (
        this.selected &&
        this.selected.id === record.id
      ) {
        this.selected = undefined;
      } else {
        this.selected = record;
      }
    }
  }

}
