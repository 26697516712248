import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CommonService } from './common.service';
import { Location } from '@angular/common';
import { IPayload } from '../models';
import { Observable } from 'rxjs';
import { DeleteInputMappingRecord } from '../store/actions';

@Injectable({
  providedIn: 'root',
})
export class LowConfidenceScoreDetailsService extends CommonService {
  private apiUrl = `https://${window.location.hostname}/v1`

  constructor(private http: HttpClient, public loc: Location) {
    super(loc);
  }

  fetchLowConfidenceScoreById(id: string): Observable<any> {
    return from(
      this.http
        .get(`${this.apiUrl}/mappings/${id}`)
        .pipe(
          catchError(this.handleError)
        )
    );
  }

  fetchLowConfidenceAuthoritativeRecords(payload: any): Observable<any> {
    return from(
      this.http
        .get(`${this.apiUrl}/authoritativerecords?authoritativeRecordIds=${payload.authoritativeRecordIds.join(',')}&from=${payload.from}&size=${payload.size}&query=${payload.query}&sort=${payload.sort}`)
        .pipe(
          catchError(this.handleError)
        )
    );
  }
}