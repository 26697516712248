import { Injectable } from '@angular/core';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import {
    UserActionTypes, 
    LoginUser,
    LoginUserSuccess,
    LoginUserFailed,
    LogoutUser,
    LogoutUserSuccess,
    LogoutUserFailed
} from '../actions';

@Injectable()
export class UserEffects {
  constructor(private actions$: Actions, private authService: AuthService) {}

  // Login Effect
  @Effect()
  userLogin$ = this.actions$.pipe(
    ofType(UserActionTypes.LoginUser),
    switchMap((action: LoginUser) =>
      this.authService.loginWithGoogle().pipe(
        map((user: any) => new LoginUserSuccess(user)),
        catchError(error => of(new LoginUserFailed({ message: error.error ? error.error : error.message })))
      )
    )
  );

  // Logout effect
  @Effect()
  userLogout$ = this.actions$.pipe(
    ofType(UserActionTypes.LogoutUser),
    switchMap((action: LogoutUser) =>
      this.authService.logoutUser(action.payload).pipe(
        map((user: any) => new LogoutUserSuccess(user)),
        catchError(error => of(new LogoutUserFailed(error)))
      )
    )
  );
}
