import { Action } from '@ngrx/store';

export enum SettingsActionTypes {
  Sync = '[Sync] Sync the database',
  SyncSuccess = '[Sync] Sync the database success',
  SyncFailed = '[Sync] Sync the database failed',

  SyncStatus = '[SyncStatus] Check sync status',
  SyncStatusSuccess = '[SyncStatus] Check sync status success',
  SyncStatusFailed = '[SyncStatus] Check sync status failed',

  Remap = '[Remap] Remap the database',
  RemapSuccess = '[Remap] Remap the database success',
  RemapFailed = '[Remap] Remap the database failed',

  CheckRemapStatus = '[Remap] Check remap status',
  CheckRemapStatusSuccess = '[Remap] Check remap status success',
  CheckRemapStatusFailed = '[Remap] Check remap status failed',
}

export class Sync implements Action {
  readonly type = SettingsActionTypes.Sync;
}

export class SyncSuccess implements Action {
  readonly type = SettingsActionTypes.SyncSuccess;
  constructor(public payload: any) {}
}

export class SyncFailed implements Action {
  readonly type = SettingsActionTypes.SyncFailed;
  constructor(public payload: any) {}
}

export class SyncStatus implements Action {
  readonly type = SettingsActionTypes.SyncStatus;
}

export class SyncStatusSuccess implements Action {
  readonly type = SettingsActionTypes.SyncStatusSuccess;
  constructor(public payload: any) {}
}

export class SyncStatusFailed implements Action {
  readonly type = SettingsActionTypes.SyncStatusFailed;
  constructor(public payload: any) {}
}

export class Remap implements Action {
  readonly type = SettingsActionTypes.Remap;
}

export class RemapSuccess implements Action {
  readonly type = SettingsActionTypes.RemapSuccess;
  constructor(public payload: any) {}
}

export class RemapFailed implements Action {
  readonly type = SettingsActionTypes.RemapFailed;
  constructor(public payload: any) {}
}

export class CheckRemapStatus implements Action {
  readonly type = SettingsActionTypes.CheckRemapStatus;
}

export class CheckRemapStatusSuccess implements Action {
  readonly type = SettingsActionTypes.CheckRemapStatusSuccess;
  constructor(public payload: any) {}
}

export class CheckRemapStatusFailed implements Action {
  readonly type = SettingsActionTypes.CheckRemapStatusFailed;
  constructor(public payload: any) {}
}

export type SettingsActions =
  | Sync
  | SyncSuccess
  | SyncFailed
  | SyncStatus
  | SyncStatusSuccess
  | SyncStatusFailed
  | Remap
  | RemapSuccess
  | RemapFailed
  | CheckRemapStatus
  | CheckRemapStatusSuccess
  | CheckRemapStatusFailed;