import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { LogoutUser } from '../store/actions';
import { Router } from '@angular/router';

@Injectable()
export class ErrorResponseInterceptor implements HttpInterceptor {
  constructor(private store: Store, private router: Router) { }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let obj = {};
        if (
          error.error &&
          typeof error.error === 'string' &&
          error.error.toLowerCase().includes('already exist')
        ) {
          obj = {
            message: 'Failed to insert! Record already exist.',
          };
        } else if (error.error && error.error.Message) {
          obj = {
            message: error.error.Message,
          };
        } else if (error.error && error.error.error) {
          obj = {
            message: error.error.error,
          };
        } else if (error.error && error.error.message) {
          obj = {
            message: error.error.message,
          };
        } else if (error.error && error.error.status && isNaN(error.error.status)) {
          obj = {
            message: error.error.status,
          };
        }

        if (error.status === 401 || error.status === 403) {
          const payload = {
            status: error.status,
          };
          this.store.dispatch(new LogoutUser(payload));
        } else if (error.status === 503) {
          this.router.navigate([`/sync`], { replaceUrl: true });
        }
        return throwError(obj);
      })
    );
  }
}
