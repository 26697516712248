import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuggestionsComponent } from './suggestions.component';
import { SuggestionsRoutingModule } from './suggestions-routing.module';
import {TabMenuModule} from 'primeng/tabmenu';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';

export function HttpLoaderFactory(http:HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}
@NgModule({
  declarations: [SuggestionsComponent],
  imports: [
    CommonModule,
    SuggestionsRoutingModule,
    TabMenuModule,
    HttpClientModule,
    TranslateModule.forChild({
      loader:{
        provide:TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps:[HttpClient]
      }
    })
  ]
})
export class SuggestionsModule { }
