import { Injectable } from '@angular/core';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { NotificationsService } from 'src/app/services/notifications.service';
import {
  FetchNotifications,
  FetchNotificationsSuccess,
  FetchNotificationsFailed,
  NotificationActionTypes,
  UpdateNotificationSuccess,
  UpdateNotificationFailed,
  FetchNotificationsCountSuccess,
  FetchNotificationsCountFailed,
} from '../actions';

@Injectable()
export class NotificationEffects {
  params: any;
  constructor(
    private actions$: Actions,
    private notificationsService: NotificationsService,
  ) {}

  @Effect()
  fetchNotifications$ = this.actions$.pipe(
    ofType(NotificationActionTypes.FetchNotifications),
    switchMap((action: FetchNotifications) =>
      this.notificationsService.fetchNotifications(action.payload).pipe(
        map((response: any) => new FetchNotificationsSuccess(response)),
        catchError((error) =>
          of(
            new FetchNotificationsFailed({
              message: error.error
                ? error.error
                : error.message
                ? error.message
                : error,
            })
          )
        )
      )
    )
  );

  @Effect()
  fetchNotificationsCount$ = this.actions$.pipe(
    ofType(NotificationActionTypes.FetchNotificationsCount),
    switchMap(() =>
      this.notificationsService.fetchNotificationsCount().pipe(
        map((response: any) => new FetchNotificationsCountSuccess(response)),
        catchError((error) =>
          of(
            new FetchNotificationsCountFailed({
              message: error.error ? error.error : error.message,
            })
          )
        )
      )
    )
  );

  @Effect()
  fetchNotification$ = this.actions$.pipe(
    ofType(NotificationActionTypes.FetchNotification),
    switchMap((action: any) =>
      this.notificationsService.fetchNotification(action.payload).pipe(
        map((response: any) => new FetchNotificationsSuccess(response)),
        catchError((error) =>
          of(
            new FetchNotificationsFailed({
              message: error.error
                ? error.error
                : error.message
                ? error.message
                : error,
            })
          )
        )
      )
    )
  );

  @Effect()
  updateNotification$ = this.actions$.pipe(
    ofType(NotificationActionTypes.UpdateNotification),
    switchMap((action: any) =>
      this.notificationsService.updateNotification(action.payload).pipe(
        map((response: any) => new UpdateNotificationSuccess(response)),
        catchError((error) =>
          of(
            new UpdateNotificationFailed({
              message: error.error
                ? error.error
                : error.message
                ? error.message
                : error,
            })
          )
        )
      )
    )
  );
  
}
