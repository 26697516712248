import { Injectable } from '@angular/core';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { map, catchError, switchMap, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { SettingsService } from 'src/app/services/settings.service';
import { CheckRemapStatus, CheckRemapStatusFailed, CheckRemapStatusSuccess, Remap, RemapFailed, RemapSuccess, SettingsActionTypes, Sync, SyncFailed, SyncStatus, SyncStatusFailed, SyncStatusSuccess, SyncSuccess } from '../actions/settings.actions';

@Injectable()
export class SettingsEffects {
  constructor(
    private actions$: Actions,
    private settingsService: SettingsService
  ) {}

  @Effect()
  sync$ = this.actions$.pipe(
    ofType(SettingsActionTypes.Sync),
    switchMap((action: Sync) =>
      this.settingsService.sync().pipe(
        map((response: any) => new SyncSuccess(response)),
        catchError((error) =>
          of(
            new SyncFailed({
              message: error.error
                ? error.error
                : error.message
                ? error.message
                : error,
            })
          )
        )
      )
    )
  );

  @Effect()
  remap$ = this.actions$.pipe(
    ofType(SettingsActionTypes.Remap),
    switchMap((action: Remap) =>
      this.settingsService.remap().pipe(
        map((response: any) => new RemapSuccess(response)),
        catchError((error) =>
          of(
            new RemapFailed({
              message: error.error
                ? error.error
                : error.message
                ? error.message
                : error,
            })
          )
        )
      )
    )
  );

  @Effect()
  checkRemapStatus$ = this.actions$.pipe(
    ofType(SettingsActionTypes.CheckRemapStatus),
    switchMap((action: CheckRemapStatus) =>
      this.settingsService.checkRemapStatus().pipe(
        map((response: any) => new CheckRemapStatusSuccess(response)),
        catchError((error) =>
          of(
            new CheckRemapStatusFailed({
              message: error.error
                ? error.error
                : error.message
                ? error.message
                : error,
            })
          )
        )
      )
    )
  );

  @Effect()
  getSyncStatus$ = this.actions$.pipe(
    ofType(SettingsActionTypes.SyncStatus),
    switchMap((action: SyncStatus) =>
      this.settingsService.getSyncStatus().pipe(
        map((response: any) => new SyncStatusSuccess(response)),
        catchError((error) =>
          of(
            new SyncStatusFailed({
              message: error.error
                ? error.error
                : error.message
                ? error.message
                : error,
            })
          )
        )
      )
    )
  );

}
