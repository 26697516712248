import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private afAuth:AngularFireAuth,
    public authService: AuthService,
    public router: Router
  ){}

  // Authentication guard method
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return new Promise((resolve, reject) => {
        this.afAuth.onAuthStateChanged((user: any) => {
          if (user) {
            localStorage.setItem('user', JSON.stringify(user));
            JSON.parse(localStorage.getItem('user'));
            resolve(true);
          } else {
            this.router.navigate(['login']);
            resolve(false);
          }
        })
      });  
  }
  
}
