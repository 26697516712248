import { Injectable } from '@angular/core';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { 
  FetchLowConfidenceAuthoritativeRecordsFailed, 
  FetchLowConfidenceAuthoritativeRecordsSuccess, 
  FetchLowConfidenceScoreById, 
  FetchLowConfidenceScoreByIdFailed, 
  FetchLowConfidenceScoreByIdSuccess, 
  LowConfidenceScoreDetailsActionTypes } from '../actions/low.confidence.score.details.actions';
import { LowConfidenceScoreDetailsService } from 'src/app/services/low.confidence.score.details.service';

@Injectable()
export class LowConfidenceScoreDetailsEffects {
  constructor(
    private actions$: Actions,
    private lowConfidenceScoreDetailsService: LowConfidenceScoreDetailsService,
  ) {}
  
  @Effect()
  fetchLowConfidenceScoreById$ = this.actions$.pipe(
    ofType(LowConfidenceScoreDetailsActionTypes.FetchLowConfidenceScoreById),
    switchMap((action: FetchLowConfidenceScoreById) =>
      this.lowConfidenceScoreDetailsService
        .fetchLowConfidenceScoreById(action.id)
        .pipe(
          map((response: any) => new FetchLowConfidenceScoreByIdSuccess(response)),
          catchError((error) =>
            of(
              new FetchLowConfidenceScoreByIdFailed({
                message: error.error ? error.error : error.message,
              })
            )
          )
        )
    )
  );

  //FetchLowConfidenceAuthoritativeRecords
  @Effect()
  fetchLowConfidenceAuthoritativeRecords$ = this.actions$.pipe(
    ofType(LowConfidenceScoreDetailsActionTypes.FetchLowConfidenceAuthoritativeRecords),
    switchMap((action: any) =>
      this.lowConfidenceScoreDetailsService
        .fetchLowConfidenceAuthoritativeRecords(action.payload)
        .pipe(
          map((response: any) => new FetchLowConfidenceAuthoritativeRecordsSuccess(response)),
          catchError((error) =>
            of(
              new FetchLowConfidenceAuthoritativeRecordsFailed({
                message: error.error ? error.error : error.message,
              })
            )
          )
        )
    )
  );

}
