import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Location } from '@angular/common';
import { from } from 'rxjs';
import { CommonService } from './common.service';
import { IPayload } from '../models';

@Injectable({
  providedIn: 'root',
})
export class AuthoritativeRecordsService extends CommonService {
  constructor(private http: HttpClient, public loc: Location) {
    super(loc);
  }

  fetchAuthoritativeRecordById(id: any) {
    return from(
      this.http
        .get<any>(
          `${this.baseUrl}/${this.environment.service.apiStage}/authoritativerecords/${id}`
        )
        .pipe(
          map((response) => response),
          catchError(this.handleError)
        )
    );
  }

  fetchInputMappingsByAuthoritativeRecordId(payload: any) {
    let body = {
      from: payload.from,
      size: payload.size,
      sort: payload.sort
    };

    return from(
      this.http
        .get<any>(
          `${this.baseUrl}/${this.environment.service.apiStage}/authoritativerecords/${payload.id}/mappings`,
          { params: body }
        )
        .pipe(
          map((response) => response),
          catchError(this.handleError)
        )
    );
  }

  fetchAuthoritativeRecords(payload: any) {
    let body: IPayload = {
      from: payload.from,
      size: payload.size,
      query: payload.query,
      entity_type: payload.entityType,
      sort: payload.sort
    };

    return from(
      this.http
        .get<any>(
          `${this.baseUrl}/${this.environment.service.apiStage}/authoritativerecords`,
          { params: body }
        )
        .pipe(
          catchError(this.handleError)
        )
    );
  }

  // Function to move Input record to another authoritative record
  updateInputRecord(payload: any) {
    let updatedBy = JSON.parse(localStorage.getItem('user'));
    return from(
      this.http
        .patch<any>(
          `${this.baseUrl}/${this.environment.service.apiStage}/mappings/${payload.input_record_id}`,{
            updatedBy: updatedBy.displayName,
            autht_record_id: payload.autht_record_id,
            ignored: payload.ignored
          }
        )
        .pipe(
          map((response) => response),
          catchError(this.handleError)
        )
    );
  }

  // Function to delete input mapping record
  deleteInputRecordMapping(payload: any) {
    return from(
      this.http
        .delete(
          `${this.baseUrl}/${this.environment.service.apiStage}/mappings/${payload.id}`,
        )
        .pipe(
          map((response) => response),
          catchError(this.handleError)
        )
    );
  }
}
