import { Injectable } from '@angular/core';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  ExportFile,
  ExportFileFailed,
  ExportFileSuccess,
  FetchUploadStatus,
  FetchUploadStatusFailed,
  FetchUploadStatusSuccess,
  FetchUploadUrl,
  FetchUploadUrlFailed,
  FetchUploadUrlSuccess,
  ImportData,
  ImportDataFailed,
  ImportDataSuccess,
  ImportExportActionTypes,
  UploadFile,
  UploadFileFailed,
  UploadFileSuccess,
} from '../actions/import.export.actions';
import { ImportExportService } from 'src/app/services/import-export.service';

@Injectable()
export class ImportExportEffects {
  constructor(
    private actions$: Actions,
    private importExportService: ImportExportService
  ) {}

  @Effect()
  exportFile$ = this.actions$.pipe(
    ofType(ImportExportActionTypes.ExportFile),
    switchMap((action: ExportFile) =>
      this.importExportService.exportFile(action.payload).pipe(
        map((response: any) => new ExportFileSuccess(response)),
        catchError((error) =>
          of(
            new ExportFileFailed({
              message: error.error ? error.error : error.message,
            })
          )
        )
      )
    )
  );

  @Effect()
  getUploadUrl$ = this.actions$.pipe(
    ofType(ImportExportActionTypes.FetchUploadUrl),
    switchMap((action: FetchUploadUrl) =>
      this.importExportService.fetchUploadUrl(action.payload).pipe(
        mergeMap((response: any) => [
          new FetchUploadUrlSuccess(response),
          new UploadFile({ ...response, ...action.payload }),
        ]),
        catchError((error) =>
          of(
            new FetchUploadUrlFailed({
              message: error.error ? error.error : error.message,
            })
          )
        )
      )
    )
  );

  @Effect()
  uploadFile$ = this.actions$.pipe(
    ofType(ImportExportActionTypes.UploadFile),
    switchMap((action: UploadFile) =>
      this.importExportService
        .uploadFile(action.payload.upload_url, action.payload.file)
        .pipe(
          mergeMap((response: any) => {
            const payload = {
              key: action.payload.file_name,
              password: action.payload.password,
            };
            return [new UploadFileSuccess(response), new ImportData(payload)];
          }),
          catchError((error) =>
            of(
              new UploadFileFailed({
                message: error.error ? error.error : error.message,
              })
            )
          )
        )
    )
  );

  @Effect()
  importdb$ = this.actions$.pipe(
    ofType(ImportExportActionTypes.ImportData),
    switchMap((action: ImportData) =>
      this.importExportService.importDb(action.payload).pipe(
        mergeMap((response: any) => {
          const payload = { req_id: response.req_id };
          return [
          new ImportDataSuccess(response),
          new FetchUploadStatus(payload),
        ]}),
        catchError((error) =>
          of(
            new ImportDataFailed({
              message: error.error ? error.error : error.message,
            })
          )
        )
      )
    )
  );

  @Effect()
  getUploadStatus$ = this.actions$.pipe(
    ofType(ImportExportActionTypes.FetchUploadStatus),
    switchMap((action: FetchUploadStatus) =>
      this.importExportService.fetchUploadStatus(action.payload.req_id).pipe(
        map((response: any) => new FetchUploadStatusSuccess(response)),
        catchError((error) =>
          of(
            new FetchUploadStatusFailed({
              message: error.error ? error.error : error.message,
            })
          )
        )
      )
    )
  );
}
