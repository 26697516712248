import { MetaReducer } from '@ngrx/store';
/**
 * storeFreeze prevents state from being mutated. When mutation occurs, an
 * exception will be thrown.
 */
import { storeFreeze } from 'ngrx-store-freeze';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { userReducer } from './reducers/user.reducer';
import {
  UserEffects,
  LowConfidenceScoreEffects,
  NotificationEffects,
  ImportExportEffects,
  SynchronizeEffects,
  CommonEffects,
  AuthoritativeRecordEffects
} from './effects';
import {
  IAppState,
  ILowConfidenceScoreState,
  IUserState,
  IImportExportState,
  ISynchronizeState,
  ICommon,
  INotificationsState,
  INotificationsFilterState,
  ILowConfidenceScoreFilterState,
  IAuthoritativeRecordsState,
  IAuthoritativeRecordsFilterState,
  IAuthoritativeRecordDetailsState,
} from '../models';
import { commonReducer } from './reducers/common.reducer';
import { authoritativeRecordsFilterReducer, authoritativeRecordsReducer } from './reducers/authoritative.record.reducer';
import { suggestionReducer } from './reducers/suggestion.reducer';
import { lowConfidenceScoreFilterReducer, lowConfidenceScoreReducer } from './reducers/low.confidence.score.reducer';
import { importExportRecducer } from './reducers/import.export.reducer';
import { synchronizeReducer } from './reducers/synchronize.reducer';
import { notificationFilterReducer, notificationReducer } from './reducers/notification.reducer';
import { app_environment } from 'src/app.environment';
import { ISettingsState } from '../models/settings';
import { SettingsEffects } from './effects/settings';
import { settingsReducer } from './reducers/settings.reducer';
import { authoritativeRecordDetailsFilterReducer, authoritativeRecordDetailsReducer } from './reducers/authoritative.record.details.reducer';
import { AuthoritativeRecordDetailsEffects } from './effects/authoritative.record.details.effects';
import { lowConfidenceScoreDetailsFilterReducer, lowConfidenceScoreDetailsReducer } from './reducers/low.confidence.score.details.reducer';
import { ILowConfidenceScoreDetailsFilterState, ILowConfidenceScoreDetailsState } from '../models/low.confidence.score.details';
import { LowConfidenceScoreDetailsEffects } from './effects/low.confidence.score.details.effects';

export const reducers: any = {
  authoritativeRecords: authoritativeRecordsReducer,
  authoritativeRecordsFilter: authoritativeRecordsFilterReducer,
  authoritativeRecordDetails: authoritativeRecordDetailsReducer,
  authoritativeRecordDetailsFilter: authoritativeRecordDetailsFilterReducer,
  lowConfidenceScores: lowConfidenceScoreReducer,
  lowConfidenceScoresFilter: lowConfidenceScoreFilterReducer,
  lowConfidenceScoreDetails: lowConfidenceScoreDetailsReducer,
  lowConfidenceScoreDetailsFilter: lowConfidenceScoreDetailsFilterReducer,
  user: userReducer,
  common: commonReducer,
  suggestions: suggestionReducer,
  importExport: importExportRecducer,
  synchronize: synchronizeReducer,
  notifications: notificationReducer,
  settings: settingsReducer,
  notificationsFilter: notificationFilterReducer
};

// create user selectors
export const getUserState = createFeatureSelector<IUserState>('user');
export const getUser = createSelector(
  getUserState,
  (state: IUserState) => state.userData
);
export const getLoginError = createSelector(
  getUserState,
  (state: IUserState) => state.error
);

//create common selector
export const getCommonState = createFeatureSelector<ICommon>('common');
export const getCommonData = createSelector(
  getCommonState,
  (state: ICommon) => state
);

export const getAuthoritativeRecordsState = 
  createFeatureSelector<IAuthoritativeRecordsState>('authoritativeRecords');
export const getAuthoritativeRecordsData = createSelector(
  getAuthoritativeRecordsState,
  (state: IAuthoritativeRecordsState) => state
);

export const getAuthoritativeRecordsFilterState = 
  createFeatureSelector<IAuthoritativeRecordsFilterState>('authoritativeRecordsFilter');
export const getAuthoritativeRecordsFilterData = createSelector(
  getAuthoritativeRecordsFilterState,
  (state: IAuthoritativeRecordsFilterState) => state
);

export const getNotificationsState =
  createFeatureSelector<INotificationsState>('notifications');
export const getNotificationsData = createSelector(
  getNotificationsState,
  (state: INotificationsState) => state
);

export const getSettingsState = 
  createFeatureSelector<ISettingsState>('settings');
export const getSettingsData = createSelector(
  getSettingsState,
  (state: ISettingsState) => state
);

export const getNotificationsFilterState = 
  createFeatureSelector<INotificationsFilterState>('notificationsFilter');
export const getNotificationsFilterData = createSelector(
  getNotificationsFilterState,
  (state: INotificationsFilterState) => state
);

// create low confidence score records selectors
export const getLowConfidenceScoreState =
  createFeatureSelector<ILowConfidenceScoreState>('lowConfidenceScores');
export const getLowConfidenceScoreData = createSelector(
  getLowConfidenceScoreState,
  (vstate: ILowConfidenceScoreState) => vstate
);

export const getLowConfidenceScoreFilterState =
  createFeatureSelector<ILowConfidenceScoreFilterState>('lowConfidenceScoresFilter');
export const getLowConfidenceScoreFilterData = createSelector(
  getLowConfidenceScoreFilterState,
  (vstate: ILowConfidenceScoreFilterState) => vstate
);

export const getLowConfidenceScoreDetailsState = 
  createFeatureSelector<ILowConfidenceScoreDetailsState>('lowConfidenceScoreDetails');
export const getLowConfidenceScoreDetailsData = createSelector(
  getLowConfidenceScoreDetailsState,
  (state: ILowConfidenceScoreDetailsState) => state
);

export const getLowConfidenceScoreDetailsFilterState = 
  createFeatureSelector<ILowConfidenceScoreDetailsFilterState>('lowConfidenceScoreDetailsFilter');
export const getLowConfidenceScoreDetailsFilterData = createSelector(
  getLowConfidenceScoreDetailsFilterState,
  (state: ILowConfidenceScoreDetailsFilterState) => state
);

// create low confidence score records selectors
export const importExportState =
  createFeatureSelector<IImportExportState>('importExport');
export const getImportExportData = createSelector(
  importExportState,
  (state: IImportExportState) => state
);

// create synchronize selectors
export const synchronizeState =
  createFeatureSelector<ISynchronizeState>('synchronize');
export const getSyncData = createSelector(
  synchronizeState,
  (state: ISynchronizeState) => state
);

export const getAuthoritativeRecordDetailsState =
  createFeatureSelector<IAuthoritativeRecordDetailsState>('authoritativeRecordDetails');
export const getAuthoritativeRecordDetailsData = createSelector(
  getAuthoritativeRecordDetailsState,
  (state: IAuthoritativeRecordDetailsState) => state
);

export const getAuthoritativeRecordDetailsFilterState =
  createFeatureSelector<IAuthoritativeRecordsFilterState>('authoritativeRecordDetailsFilter');
export const getAuthoritativeRecordDetailsFilterData = createSelector(
  getAuthoritativeRecordDetailsFilterState,
  (state: IAuthoritativeRecordsFilterState) => state
);

// selectors
export const selectUser = (state: IUserState) => state.userData;
export const getLanguage = (state: IAppState) => state.selectedLanguage;
export const getLowConfidenceScoreStateData = (state: ILowConfidenceScoreState) => state;
export const getLowConfidenceScoreFilterStateData = (state: ILowConfidenceScoreFilterState) => state;
export const getLowConfidenceScoreDetailsStateData = (state: ILowConfidenceScoreDetailsState) => state;
export const getLowConfidenceScoreDetailsFilterStateData = (state: ILowConfidenceScoreFilterState) => state;
export const getAuthoritativeRecordsStateData = (state: IAuthoritativeRecordsState) => state;
export const getAuthoritativeRecordsFilterStateData = (state: IAuthoritativeRecordsFilterState) => state;
export const getAuthoritativeRecordDetailsStateData = (state: IAuthoritativeRecordsState) => state;
export const getAuthoritativeRecordDetailsFilterStateData = (state: IAuthoritativeRecordsFilterState) => state;
export const getNotificationStateData = (state: INotificationsState) => state;
export const getNotificationFilterStateData = (state: INotificationsFilterState) => state;
export const getImportExportStateData = (state: IImportExportState) => state;
export const getSynchronizeStateData = (state: ISynchronizeState) => state;
export const getSettingsStateData = (state: ISettingsState) => state;

export const metaReducers: MetaReducer<IAppState>[] = !app_environment.production
  ? [storeFreeze]
  : [];
export const effects: any[] = [
  UserEffects,
  AuthoritativeRecordEffects,
  LowConfidenceScoreEffects,
  LowConfidenceScoreDetailsEffects,
  NotificationEffects,
  ImportExportEffects,
  SynchronizeEffects,
  CommonEffects,
  SettingsEffects,
  AuthoritativeRecordDetailsEffects
];
