import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import 'firebase/auth';
import 'firebase/firestore';
import firebase from 'firebase/compat/app';
import { Observable, from } from 'rxjs';
import { Router } from '@angular/router';
import { CommonService } from './common.service';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends CommonService {
  userData: any;
  constructor(
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    private ngZone: NgZone,
    public loc: Location,
    private http: HttpClient
  ) {
    super(loc);
  }

  // Auth logic to run auth providers
  loginWithGoogle(): Observable<any> {
    var provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope(
      'https://www.googleapis.com/auth/cloud-identity.groups.readonly'
    );
    return from(
      this.afAuth
        .signInWithPopup(
          provider.setCustomParameters({
            prompt: 'select_account',
          })
        )
        .then((result: any) => {
          // required to get auth token from firbase to send it in request headers
          firebase
            .auth()
            .currentUser.getIdToken()
            .then((value: any) => {
              let obj = {
                idToken: value,
                credential: result.credential,
                user: result.user
              };
              localStorage.setItem('oAuth', JSON.stringify(obj));
            });
          this.ngZone.run(() => this.router.navigate(['authoritativerecords'])).then();
          return result?.additionalUserInfo?.profile;
        })
        .catch((error: any) => {
          throw new Error(error);
        })
    );
  }

  // function to get authorization token
  getIdToken() {
    if (firebase.auth().currentUser) {
      return from(
        firebase
          .auth()
          .currentUser.getIdToken()
          .then((value: any) => {
            const obj = JSON.parse(localStorage.getItem('oAuth'))
            obj["idToken"] = value
            localStorage.setItem('oAuth', JSON.stringify(obj));
            return value;
          })
      );
    } else return new Observable<any>();
  }

  // Sign out
  logoutUser(payload: any) {
    return from(
      this.afAuth.signOut().then(() => {
        this.router.navigate(['login']);
        if (payload && (payload.status || payload.message)) {
          return payload;
        }
      })
    );
  }

  fetchAppData(){
    return from(      
      this.http
        .get<any>(`/assets/appversion.json?_=${new Date().getTime()}`)
        .pipe(
          map((response: any) => response),
          catchError(this.handleError)
        )
      )
  }
}