import { UserActionTypes, LoginUserActions } from '../actions/user.actions';
import { IUserState } from 'src/app/models';

const initialUserState: IUserState = {
  userData: null,
  error:null
};

export function userReducer(
  state = initialUserState,
  action: LoginUserActions
): IUserState {
  switch (action.type) {
    case UserActionTypes.LoginUser:
      return { userData:null, error:null };
    case UserActionTypes.LoginUserSuccess:
      return {
        userData: action.payload,
        error:null
      };
    case UserActionTypes.LoginUserFailed:
      return {
        userData: null,
        error:action.payload.message
      };
    case UserActionTypes.LogoutUser:
      return {
        ...state,
        error: null
      };
    case UserActionTypes.LogoutUserSuccess:
      localStorage.setItem('user', null);
      localStorage.removeItem('oAuth');
      localStorage.removeItem('authoritative_record');
      localStorage.removeItem('low_confidence_score');
      return {
        userData: action.payload,
        error: action.payload && !action.payload.message && action.payload.status && (action.payload.status === 401 || action.payload.status === 403) ? "Unauthorized user!" : (action.payload && action.payload.message ? action.payload.message : null)
      };
    case UserActionTypes.LogoutUserFailed:
      return {
        userData: action.payload,
        error:null
      };
    default:
      return state;
  }
}
