import { Component, Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Store } from '@ngrx/store';
import { FetchLowConfidenceScoresCount } from 'src/app/store/actions/low.confidence.score.actions';
import { getLowConfidenceScoreData, getNotificationStateData, getNotificationsData } from 'src/app/store';
import { FetchNotificationsCount } from 'src/app/store/actions';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css'],
})
export class NavigationComponent implements OnInit {
  items: MenuItem[];
  activeItem: MenuItem;
  lowConfidenceScoreCount: number;
  notificationsCount: number;
  constructor(private router: Router, private store: Store) {}

  ngOnInit(): void {
    let user = JSON.parse(localStorage.getItem("user"))
    this.items = [
      { label: 'Authoritative Records', routerLink: ['/authoritativerecords'] },
      { label: 'Low Confidence Scores', routerLink: ['/confidencescores'] },
      { label: 'Notifications', routerLink: ['/notifications'] },
      { label: 'Settings', routerLink: ['/settings'] }
    ];
    if(user && user !== null) {
      // if user has logged in
      this.store.dispatch(new FetchLowConfidenceScoresCount());
      this.store.dispatch(new FetchNotificationsCount());
    }
    this.store.select(getLowConfidenceScoreData).subscribe((data) => {
      if (data) {
        this.lowConfidenceScoreCount = data.totalUnapprovedRecordsCount;
      }
    });
    this.store.select(getNotificationsData).subscribe((data) => {
      if (data) {
        this.notificationsCount = data.totalNumPendingNotifications;
      }
    });
    
    this.activeItem = this.items[0];
    // Retain the active tab  in the navigation menu on refreshing the page
    this.router.events.subscribe((event: any) => {
      if (event.url) {
        if (event.url !== '/') {
          this.activeItem = this.items.filter((itm) =>
            event.url.includes(itm.routerLink[0])
          )[0];
        } else {
          this.activeItem = this.items[0];
        }
      }
    });
  }
}
