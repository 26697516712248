import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SynchronizeComponent } from './synchronize.component';
import { AppSharedModule } from 'src/app/app-shared/app-shared.module';
import { SynchronizeRoutingModule } from './synchronize-routing.module';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';


@NgModule({
  declarations: [SynchronizeComponent],
  imports: [
    CommonModule,
    AppSharedModule,
    SynchronizeRoutingModule,
    ButtonModule,
    ConfirmDialogModule
  ]
})
export class SynchronizeModule { }
