import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CommonService } from './common.service';
import { Location } from '@angular/common';
import { IPayload } from '../models';
import { Observable } from 'rxjs';
import { DeleteInputMappingRecord } from '../store/actions';

@Injectable({
  providedIn: 'root',
})
export class LowConfidenceScoreService extends CommonService {
  private apiUrl = `https://${window.location.hostname}/v1`

  constructor(private http: HttpClient, public loc: Location) {
    super(loc);
  }

  updateLowConfidenceScore(payload: any): Observable<any> {
    return from(
      this.http
        .patch(`${this.apiUrl}/mappings/${payload.mappingId}`, payload.normalizationMappingUpdate)
        .pipe(
          catchError(this.handleError)
        )
    );
  }

  updateLowConfidenceScoresMany(payload: any): Observable<any> {
    return from(
      this.http
        .patch(`${this.apiUrl}/mappings`, payload)
        .pipe(
          catchError(this.handleError)
        )
    );
  }

  fetchLowConfidenceScoreList(payload: any) {
    let body: IPayload = {
      from: payload.from,
      size: payload.size,
      query: payload.query,
      entity_type: payload.entity_type,
      approved: 'false',
    };
    if (payload.ignored != null) {
      body['ignored'] = payload.ignored;
    }
    if (payload && payload.sort && payload.sort !== '') {
      const sortArr = payload.sort.split(':');
      const order = sortArr[1] ? sortArr[1] : 'asc';
      body['sort'] = `${sortArr[0]}:${order}`;
    }

    if (payload.filters && payload.filters !== '') {
      const filters = JSON.parse(payload.filters);
      const typesArr = filters.types;
      body['entity_type'] = typesArr.map((typ: any) => typ.type).join(',');
    }
    return from(
      this.http
        .get<any>(
          `${this.baseUrl}/${this.environment.service.apiStage}/mappings`,
          { params: body }
        )
        .pipe(
          catchError(this.handleError)
        )
    );
  }

  // Fetch the low confidence score records count
  fetchLowConfidenceScoreCount() {
    return from(
      this.http
        .get<any>(
          `${this.baseUrl}/${this.environment.service.apiStage}/mappings/unapproved-count`
        )
        .pipe(
          map((response) => response),
          catchError(this.handleError)
        )
    );
  }

  getMappingById(id: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/mappings/${id}`);
  }

  getUnapprovedAuthtRecordsByIds(ids: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/authoritativerecords?authoritativeRecordIds=${ids}`);
  }

  approveInputRecordMapping(mappingRecordId: string, authoritativeRecordId: string): Observable<any> {
    return this.http.patch(`${this.apiUrl}/mappings/${mappingRecordId}`, { 
      "updated_by": "admin", 
      "autht_record_id": authoritativeRecordId,
      "ignored": false
    }, { observe: 'response' });
  }

  deleteInputMappingRecord(mappingRecordId: string): Observable<any> {
    return this.http.delete(`${this.apiUrl}/mappings/${mappingRecordId}`, { observe: 'response' });
  }
}