import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prettyPrint'
})
export class PrettyPrintPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return value;
    }

    return value.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
  }

}
