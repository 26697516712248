import { Action } from "@ngrx/store";

export enum SuggestionActionTypes {
    GetSuggestionCount = "[Suggestions] Get Pending Suggestions Count",
    GetSuggestionCountSuccessful = "[Suggestions] Get Pending Suggestions Count Success",
    GetSuggestionCountFailed = "[Suggestions] Get Pending Suggestions Count Failure"
}


export class GetSuggestionCount implements Action {
    readonly type = SuggestionActionTypes.GetSuggestionCount;
}

export class GetSuggestionCountSuccessful implements Action {
    readonly type = SuggestionActionTypes.GetSuggestionCountSuccessful;
    constructor(public payload?: any) {}
}

export class GetSuggestionCountFailed implements Action {
    readonly type = SuggestionActionTypes.GetSuggestionCountFailed;
    constructor(public payload?: any) {}
}

export type SuggestionActions = GetSuggestionCount | GetSuggestionCountSuccessful | GetSuggestionCountFailed;