import { IAuthoritativeRecordsFilterState, IAuthoritativeRecordsState } from 'src/app/models';
import {
  AuthoritativeRecordActionTypes,
  AuthoritativeRecordActions,
} from '../actions/authoritative.record.actions';

const initialAuthoritativeRecordsFilterState: IAuthoritativeRecordsFilterState = {
  from: 0,
  size: 50,
  type: "",
  sort: "standardizedRecordName:asc",
  query: "",
  entityType: "",
  first: 0
}

export function authoritativeRecordsFilterReducer(
  state: any = initialAuthoritativeRecordsFilterState,
  action: AuthoritativeRecordActions
) {
  switch (action.type) {
    case AuthoritativeRecordActionTypes.UpdateAuthoritativeRecordEntityType:
      return {
        ...state,
        entityType: action.payload.entityType,
        from: 0,
        size: 50,
        first: 0
      };
    case AuthoritativeRecordActionTypes.UpdateAuthoritativeRecordSearchTerm:
      return {
        ...state,
        from: action.payload.from,
        size: action.payload.size,
        query: action.payload.query,
      };
    case AuthoritativeRecordActionTypes.UpdateAuthoritativeRecordPagination:
      return {
        ...state,
        from: action.payload.from,
        size: action.payload.size,
        sort: action.payload.sort,
      };
    case AuthoritativeRecordActionTypes.ResetAuthoritativeRecordsFilter:
      return {
        ...state,
        from: 0,
        size: 50,
        type: "",
        sort: "standardizedRecordName:asc",
        query: "",
        entityType: "",
        first: 0
      };
    default:
      return state;
  }
}

const initialAuthoritativeRecordsState: IAuthoritativeRecordsState = {
  authoritativeRecords: [],
  isLoading: false,
  entityTypes: [
    "os",
    "vendor",
    "software",
  ],
  totalRecordCount: 0
}

export function authoritativeRecordsReducer(
  state: any = initialAuthoritativeRecordsState,
  action: AuthoritativeRecordActions
) {
  switch (action.type) {
    case AuthoritativeRecordActionTypes.FetchAuthoritativeRecords:
      return {
        ...state,
        isLoading: true
      };
    case AuthoritativeRecordActionTypes.FetchAuthoritativeRecordsSuccess:
      return {
        ...state,
        isLoading: false,
        totalRecordCount: action.payload.count,
        authoritativeRecords: action.payload.authoritative_records
      };
    case AuthoritativeRecordActionTypes.FetchAuthoritativeRecordsFailed:
      return {
        ...state,
        isLoading: false
      };
    case AuthoritativeRecordActionTypes.UpdateAuthoritativeRecordPagination:
      return {
        ...state,
        from: action.payload.from,
        size: action.payload.size,
        sort: action.payload.sort,
      };
    case AuthoritativeRecordActionTypes.UpdateAuthoritativeRecordSearchTerm:
      return {
        ...state,
        from: action.payload.from,
        size: action.payload.size,
        query: action.payload.query,
      };
    default:
      return state;
  }
}