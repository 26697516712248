import { ISynchronizeState } from 'src/app/models';
import { SynchronizeActionTypes, SynchronizeActions } from '../actions';
import { ISettingsState } from 'src/app/models/settings';
import { SettingsActionTypes, SettingsActions } from '../actions/settings.actions';

const initialState: ISettingsState = {
  isSyncing: false,
  isRemapping: false,
  recordsInsertedIntoOpensearch: "0",
  recordsStreamedFromDynamo: "0",
  lastSync: "",
  lastSyncDuration: "",
  message: "",
  remapStartedAt: null
};

export function settingsReducer(
  state: ISettingsState = initialState,
  action: SettingsActions
) {
  switch (action.type) {
    case SettingsActionTypes.Sync:
      return {
        ...state,
        isSyncing: true
      };
    case SettingsActionTypes.SyncSuccess:
      return {
        ...state,
        isSyncing: true
      };
    case SettingsActionTypes.SyncFailed:
      return {
        ...state,
        isSyncing: false
      };
    case SettingsActionTypes.SyncStatusSuccess:
      return {
        ...state,
        isSyncing: action.payload.recordsAreSyncing,
        recordsInsertedIntoOpensearch: action.payload.recordsInsertedIntoOpensearch.toString(),
        recordsStreamedFromDynamo: action.payload.recordsStreamedFromDynamo.toString(),
        lastSync: action.payload.lastSync,
        lastSyncDuration: action.payload.lastSyncDuration,
        message: action.payload.message
      };
    case SettingsActionTypes.Remap:
      return {
        ...state,
        isRemapping: true,
        remapStartedAt: Date.now()
      };
    case SettingsActionTypes.RemapSuccess:
      return {
        ...state,
        isRemapping: true
      };
    case SettingsActionTypes.RemapFailed:
      return {
        ...state,
        isRemapping: false
      };
    case SettingsActionTypes.CheckRemapStatusSuccess:
      const remapStartedAt = state.remapStartedAt || (state.isRemapping ? Date.now() : null);

      const lastRemappedTime = action.payload.last_remapped ? new Date(action.payload.last_remapped).getTime() : 0;
      const isRemappingComplete = action.payload.num_records === 0 &&
        lastRemappedTime > 0 &&
        (!remapStartedAt || lastRemappedTime > remapStartedAt);

      return {
        ...state,
        isRemapping: state.isRemapping && !isRemappingComplete,
        lastRemapped: action.payload.last_remapped,
        remapStartedAt: remapStartedAt
      };
    default:
      return state;
  }
}
