import { Action } from '@ngrx/store';

export enum NotificationActionTypes {
  FetchNotifications = '[Notifications Page] Fetch Notifications',
  FetchNotificationsSuccess = '[Notifications Page] Fetch Notifications Success',
  FetchNotificationsFailed = '[Notifications Page] Fetch Notifications Failed',

  FetchNotificationsCount = '[Notifications Page] Fetch Notifications Count',
  FetchNotificationsCountSuccess = '[Notifications Page] Fetch Notifications Count Success',
  FetchNotificationsCountFailed = '[Notifications Page] Fetch Notifications Count Failed',

  FetchNotification = '[Notification Page] Fetch Notification',
  FetchNotificationSuccess = '[Notification Page] Fetch Notification Success',
  FetchNotificationFailed = '[Notification Page] Fetch Notification Failed',

  UpdateNotification = '[Notification Page] Update Notification',
  UpdateNotificationSuccess = '[Notification Page] Update Notification Success',
  UpdateNotificationFailed = '[Notification Page] Update Notification Failed',

  ResetNotificationsMessages = "[Notifications] Reset Notifications Messages",

  FetchNotificationsFilter = '[Notifications Page] Fetch Notifications Filter',
  FetchNotificationsFilterSuccess = '[Notifications Page] Fetch Notifications Filter Success',
  FetchNotificationsFilterFailed = '[Notifications Page] Fetch Notifications Filter Failed',

  UpdateNotificationType = '[Notification Page] Update Notification Type',
  UpdateNotificationState = '[Notification Page] Update Notification State',
  UpdateNotificationChangeType = '[Notification Page] Update Notification Change Type',
  UpdateNotificationEntityType = '[Notification Page] Update Notification Entity Type',
  UpdateNotificationSearchTerm = '[Notification Page] Update Notification Search Term',
  UpdateNotificationSort = '[Notification Page] Update Notification Sort',
  UpdateNotificationPagination = '[Notification Page] Update Notification Pagination',

  ResetNotificationsFilter = '[Notifications Page] Reset Notifications Filter',
}

export class ResetNotificationsMessages implements Action {
  readonly type = NotificationActionTypes.ResetNotificationsMessages;
}

export class FetchNotifications implements Action {
  readonly type = NotificationActionTypes.FetchNotifications;
  constructor(public payload: any){}
}

export class FetchNotificationsSuccess implements Action {
  readonly type = NotificationActionTypes.FetchNotificationsSuccess;
  constructor(public payload: any){}
}

export class FetchNotificationsFailed implements Action {
  readonly type = NotificationActionTypes.FetchNotificationsFailed;
  constructor(public payload: any){}
}

export class FetchNotificationsCount implements Action {
  readonly type = NotificationActionTypes.FetchNotificationsCount;
  constructor(){}
}

export class FetchNotificationsCountSuccess implements Action {
  readonly type = NotificationActionTypes.FetchNotificationsCountSuccess;
  constructor(public payload: any){}
}

export class FetchNotificationsCountFailed implements Action {
  readonly type = NotificationActionTypes.FetchNotificationsCountFailed;
  constructor(public payload: any){}
}

export class FetchNotification implements Action {
  readonly type = NotificationActionTypes.FetchNotification;
  constructor(public payload: any){}
}

export class FetchNotificationSuccess implements Action {
  readonly type = NotificationActionTypes.FetchNotificationSuccess;
  constructor(public payload: any){}
}

export class FetchNotificationFailed implements Action {
  readonly type = NotificationActionTypes.FetchNotificationFailed;
  constructor(public payload: any){}
}

export class UpdateNotification implements Action {
  readonly type = NotificationActionTypes.UpdateNotification;
  constructor(public payload: any){}
}

export class UpdateNotificationSuccess implements Action {
  readonly type = NotificationActionTypes.UpdateNotificationSuccess;
  constructor(public payload: any){}
}

export class UpdateNotificationFailed implements Action {
  readonly type = NotificationActionTypes.UpdateNotificationFailed;
  constructor(public payload: any){}
}

export class FetchNotificationsFilter implements Action {
  readonly type = NotificationActionTypes.FetchNotificationsFilter;
  constructor(){}
}

export class FetchNotificationsFilterSuccess implements Action {
  readonly type = NotificationActionTypes.FetchNotificationsFilterSuccess;
  constructor(){}
}

export class FetchNotificationsFilterFailed implements Action {
  readonly type = NotificationActionTypes.FetchNotificationsFilterFailed;
  constructor(){}
}

export class UpdateNotificationType implements Action {
  readonly type = NotificationActionTypes.UpdateNotificationType;
  constructor(public payload: any){}
}

export class UpdateNotificationState implements Action {
  readonly type = NotificationActionTypes.UpdateNotificationState;
  constructor(public payload: any){}
}

export class UpdateNotificationChangeType implements Action {
  readonly type = NotificationActionTypes.UpdateNotificationChangeType;
  constructor(public payload: any){}
}

export class UpdateNotificationEntityType implements Action {
  readonly type = NotificationActionTypes.UpdateNotificationEntityType;
  constructor(public payload: any){}
}

export class UpdateNotificationSearchTerm implements Action {
  readonly type = NotificationActionTypes.UpdateNotificationSearchTerm;
  constructor(public payload: any){}
}

export class UpdateNotificationSort implements Action {
  readonly type = NotificationActionTypes.UpdateNotificationSort;
  constructor(public payload: any){}
}

export class UpdateNotificationPagination implements Action {
  readonly type = NotificationActionTypes.UpdateNotificationPagination;
  constructor(public payload: any){}
}

export class ResetNotificationsFilter implements Action {
  readonly type = NotificationActionTypes.ResetNotificationsFilter;
  constructor() {}
}

export type NotificationsActions =
  | ResetNotificationsMessages
  | FetchNotifications
  | FetchNotificationsSuccess
  | FetchNotificationsFailed
  | FetchNotificationsCount
  | FetchNotificationsCountSuccess
  | FetchNotificationsCountFailed
  | FetchNotification
  | FetchNotificationSuccess
  | FetchNotificationFailed
  | UpdateNotification
  | UpdateNotificationSuccess
  | UpdateNotificationFailed
  | FetchNotificationsFilter
  | FetchNotificationsFilterSuccess
  | FetchNotificationsFilterFailed
  | UpdateNotificationType
  | UpdateNotificationState
  | UpdateNotificationChangeType
  | UpdateNotificationEntityType
  | UpdateNotificationSearchTerm
  | UpdateNotificationSort
  | UpdateNotificationPagination
  | ResetNotificationsFilter;
