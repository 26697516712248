import { Action } from '@ngrx/store';

export enum LowConfidenceScoreActionTypes {
  FetchLowConfidenceScores = '[Low Confidence Score Page] Low Confidence Score Records',
  FetchLowConfidenceScoresSuccess = '[Low Confidence Score Page] Low Confidence Score Records Success',
  FetchLowConfidenceScoresFailed = '[Low Confidence Score Page] Low Confidence Score Records Failed',

  FetchSelectedLowConfidenceScore = '[Low Confidence Score Details Page] Fetch Selected Low Confidence Score Details',

  ApproveMapping = '[Low Confidence Score Details Page] Approve Input Record Mapping',
  ApproveMappingSuccess = '[Low Confidence Score Details Page] Approve Input Record Mapping Success',
  ApproveMappingFailed = '[Low Confidence Score Details Page] Approve Input Record Mapping Failed',

  FetchLowConfidenceScoresCount = '[Low Confidence Score Page] Low Confidence Score Records Total Count',
  FetchLowConfidenceScoresCountSuccess = '[Low Confidence Score Page] Low Confidence Score Records Total Count Success',
  FetchLowConfidenceScoresCountFailed = '[Low Confidence Score Page] Low Confidence Score Records Total Count Failed',

  UpdateLowConfidenceScoresPagination = '[Low Confidence Score Page] Update Low Confidence Scores Pagination',
  UpdateLowConfidenceScoresEntityTypes = '[Low Confidence Score Page] Update Low Confidence Scores Entity Types',
  UpdateLowConfidenceScoresSearchTerm = '[Low Confidence Score Page] Update Low Confidence Scores Search Term',
  UpdateLowConfidenceScoresIgnored = '[Low Confidence Score Page] Update Low Confidence Scores Ignored',
  
  UpdateLowConfidenceScore = '[Low Confidence Score Page] Update Low Confidence Score',
  UpdateLowConfidenceScoreSuccess = '[Low Confidence Score Page] Update Low Confidence Score Success',
  UpdateLowConfidenceScoreFailed = '[Low Confidence Score Page] Update Low Confidence Score Failed',
  
  UpdateLowConfidenceScoresMany = '[Low Confidence Score Page] Update Low Confidence Scores Many',
  UpdateLowConfidenceScoresManySuccess = '[Low Confidence Score Page] Update Low Confidence Scores Many Success',
  UpdateLowConfidenceScoresManyFailed = '[Low Confidence Score Page] Update Low Confidence Scores Many Failed',

  ResetLowConfidenceScoresFilter = '[Low Confidence Score Page] Reset Low Confidence Scores Filter',

  ResetMessages = "[Low Confidence Score Page] Reset All Messages Showing On The Screen",

  ResetLowConfidenceScoreDetails = "[Low Confidence Score Details] Reset Low Confidence Score Details Data"
}

export class FetchLowConfidenceScores implements Action {
  readonly type = LowConfidenceScoreActionTypes.FetchLowConfidenceScores;
  constructor(public payload: any) {}
}

export class FetchLowConfidenceScoresSuccess implements Action {
  readonly type = LowConfidenceScoreActionTypes.FetchLowConfidenceScoresSuccess;
  constructor(public payload: any) {}
}

export class FetchLowConfidenceScoresFailed implements Action {
  readonly type = LowConfidenceScoreActionTypes.FetchLowConfidenceScoresFailed;
  constructor(public payload: any) {}
}

export class FetchSelectedLowConfidenceScore implements Action {
  readonly type = LowConfidenceScoreActionTypes.FetchSelectedLowConfidenceScore;
}

export class ApproveInputRecordMapping implements Action {
  readonly type = LowConfidenceScoreActionTypes.ApproveMapping;
  constructor(public payload:any) {}
}

export class ApproveInputRecordMappingSuccess implements Action {
  readonly type = LowConfidenceScoreActionTypes.ApproveMappingSuccess;
  constructor(public payload:any) {}
}

export class ApproveInputRecordMappingFailed implements Action {
  readonly type = LowConfidenceScoreActionTypes.ApproveMappingFailed;
  constructor(public payload:any) {}
}

export class FetchLowConfidenceScoresCount implements Action {
  readonly type = LowConfidenceScoreActionTypes.FetchLowConfidenceScoresCount;
}

export class FetchLowConfidenceScoresCountSuccess implements Action {
  readonly type = LowConfidenceScoreActionTypes.FetchLowConfidenceScoresCountSuccess;
  constructor(public payload:any) {}
}

export class FetchLowConfidenceScoresCountFailed implements Action {
  readonly type = LowConfidenceScoreActionTypes.FetchLowConfidenceScoresCountFailed;
  constructor(public payload:any) {}
}

export class ResetMessages implements Action {
  readonly type = LowConfidenceScoreActionTypes.ResetMessages;
}

export class ResetLowConfidenceScoreDetails implements Action {
  readonly type = LowConfidenceScoreActionTypes.ResetLowConfidenceScoreDetails
}

export class UpdateLowConfidenceScoresPagination implements Action {
  readonly type = LowConfidenceScoreActionTypes.UpdateLowConfidenceScoresPagination;
  constructor(public payload: any){}
}

export class UpdateLowConfidenceScoresEntityTypes implements Action {
  readonly type = LowConfidenceScoreActionTypes.UpdateLowConfidenceScoresEntityTypes;
  constructor(public payload: any){}
}

export class UpdateLowConfidenceScoresSearchTerm implements Action {
  readonly type = LowConfidenceScoreActionTypes.UpdateLowConfidenceScoresSearchTerm;
  constructor(public payload: any){}
}

export class UpdateLowConfidenceScoresIgnored implements Action {
  readonly type = LowConfidenceScoreActionTypes.UpdateLowConfidenceScoresIgnored;
  constructor(public payload: any){}
}

export class UpdateLowConfidenceScore implements Action {
  readonly type = LowConfidenceScoreActionTypes.UpdateLowConfidenceScore;
  constructor(public payload: any){}
}

export class UpdateLowConfidenceScoreSuccess implements Action {
  readonly type = LowConfidenceScoreActionTypes.UpdateLowConfidenceScoreSuccess;
  constructor(public payload: any){}
}

export class UpdateLowConfidenceScoreFailed implements Action {
  readonly type = LowConfidenceScoreActionTypes.UpdateLowConfidenceScoreFailed;
  constructor(public payload: any){}
}

export class UpdateLowConfidenceScoresMany implements Action {
  readonly type = LowConfidenceScoreActionTypes.UpdateLowConfidenceScoresMany;
  constructor(public payload: any){}
}

export class UpdateLowConfidenceScoresManySuccess implements Action {
  readonly type = LowConfidenceScoreActionTypes.UpdateLowConfidenceScoresManySuccess;
  constructor(public payload: any){}
}

export class UpdateLowConfidenceScoresManyFailed implements Action {
  readonly type = LowConfidenceScoreActionTypes.UpdateLowConfidenceScoresManyFailed;
  constructor(public payload: any){}
}

export class ResetLowConfidenceScoresFilter implements Action {
  readonly type = LowConfidenceScoreActionTypes.ResetLowConfidenceScoresFilter;
}

export type LowConfidenceScoreActions =
  | FetchLowConfidenceScores
  | FetchLowConfidenceScoresSuccess
  | FetchLowConfidenceScoresFailed
  | FetchSelectedLowConfidenceScore
  | ApproveInputRecordMapping
  | ApproveInputRecordMappingSuccess
  | ApproveInputRecordMappingFailed
  | FetchLowConfidenceScoresCount
  | FetchLowConfidenceScoresCountSuccess
  | FetchLowConfidenceScoresCountFailed
  | ResetMessages
  | ResetLowConfidenceScoreDetails
  | UpdateLowConfidenceScoresPagination
  | UpdateLowConfidenceScoresEntityTypes
  | UpdateLowConfidenceScoresSearchTerm
  | UpdateLowConfidenceScoresIgnored
  | UpdateLowConfidenceScore
  | UpdateLowConfidenceScoreSuccess
  | UpdateLowConfidenceScoreFailed
  | UpdateLowConfidenceScoresMany
  | UpdateLowConfidenceScoresManySuccess
  | UpdateLowConfidenceScoresManyFailed
  | ResetLowConfidenceScoresFilter;
