import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { getCommonData } from 'src/app/store';
import { GetAppVersionData } from 'src/app/store/actions';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  currentYear: number = new Date().getFullYear();
  appData: any;
  constructor(private store: Store) {
    this.store.dispatch(new GetAppVersionData());
    this.store.select(getCommonData).subscribe(data => {
      if (data) {
        this.appData = data.appVersionData;
      }
    });
  }

  ngOnInit(): void {
  }

}
