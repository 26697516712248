import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class SynchronizeService extends CommonService {
  constructor(private http: HttpClient, public loc: Location) {
    super(loc);
  }

  syncOpenSearch() {
    return from(
      this.http
        .put<any>(
          `${this.baseUrl}/${this.environment.service.apiStage}/opensearch`,
          {}
        )
        .pipe(
          map((response) => response.hits),
          catchError(this.handleError)
        )
    );
  }

  fetchSynchronizationStatus() {
    return from(
      this.http
        .get<any>(
          `${this.baseUrl}/${this.environment.service.apiStage}/sync-status`
        )
        .pipe(
          map((response) => response),
          catchError(this.handleError)
        )
    );
  }
}
