import {
  SuggestionActions,
  SuggestionActionTypes,
} from '../actions/suggestion.actions';

export interface ISuggestions {
  suggestionCount: number;
}

const initialSuggestionsState = {
  suggestionCount: 0,
};

export function suggestionReducer(
  state: ISuggestions = initialSuggestionsState,
  action: SuggestionActions
) {
  switch (action.type) {
    case SuggestionActionTypes.GetSuggestionCount:
      return state;
    case SuggestionActionTypes.GetSuggestionCountSuccessful:
      return {
        suggestionCount: action.payload,
      };
    case SuggestionActionTypes.GetSuggestionCountFailed:
      return state;
    default:
      return state;
  }
}
