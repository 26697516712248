import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  isLogin: boolean;
  constructor(private router: Router) {
    this.isLogin = false;
    // to show layout components if screen is not the Login screen
    this.router.events.subscribe((event: any) => {
      if (
        this.router &&
        this.router.url.toLowerCase() === '/login'
      ) {
        this.isLogin = true;
      } else {
        this.isLogin = false;
      }
    });
  }

  ngOnInit() {}
}
