import { ILowConfidenceScoreFilterState, ILowConfidenceScoreState } from 'src/app/models';
import {
  LowConfidenceScoreActions,
  LowConfidenceScoreActionTypes,
} from '../actions/low.confidence.score.actions';

const initialState: ILowConfidenceScoreState = {
  lowConfidenceScores: [],
  isLoading: false,
  total_input_records: 0,
  selectedRecord: {},
  unapprovedRecords: [],
  isLoadingUnapprovedRecords: false,
  notification: null,
  errorMessage: null,
  totalUnapprovedRecordsCount: 0,
  ignoredStates: [
    "Show Valid",
    "Show Ignored"
  ],
  entityTypes: [
    "os",
    "vendor",
    "software"
  ],
};

const initialFilterState: ILowConfidenceScoreFilterState = {
  from: 0,
  size: 50,
  sort: 'inputRecord:asc',
  query: '',
  entity_type: '',
  approved: false,
  ignored: false,
  selectedIgnoredStates: [
    { ignoredState: "Show Valid" },
  ],
};

export function lowConfidenceScoreReducer(
  state: ILowConfidenceScoreState = initialState,
  action: LowConfidenceScoreActions
) {
  switch (action.type) {
    case LowConfidenceScoreActionTypes.FetchLowConfidenceScores:
      return {
        ...state,
        isLoading: true,
        notification: null,
        errorMessage: null,
      };
    case LowConfidenceScoreActionTypes.FetchLowConfidenceScoresSuccess:
      return {
        ...state,
        lowConfidenceScores: action.payload.mappings,
        total_input_records: action.payload.total_records,
        isLoading: false,
      };
    case LowConfidenceScoreActionTypes.FetchSelectedLowConfidenceScore:
      let record = JSON.parse(localStorage.getItem('low_confidence_score'));
      return {
        ...state,
        unapprovedRecords: [] as any[],
        selectedRecord: record,
      };
    case LowConfidenceScoreActionTypes.FetchLowConfidenceScoresFailed:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload.message,
      };
    case LowConfidenceScoreActionTypes.ApproveMapping:
      return {
        ...state,
        notification: null,
        errorMessage: null,
      };
    case LowConfidenceScoreActionTypes.ApproveMappingSuccess:
      return {
        ...state,
        errorMessage: null,
        notification: 'Mapping approved successfully',
      };
    case LowConfidenceScoreActionTypes.ApproveMappingFailed:
      return {
        ...state,
        notification: null,
        errorMessage: action.payload.message
          ? action.payload.message
          : 'Failed to map input record',
      };
    case LowConfidenceScoreActionTypes.FetchLowConfidenceScoresCount:
      return {
        ...state,
      };
    case LowConfidenceScoreActionTypes.FetchLowConfidenceScoresCountSuccess:
      return {
        ...state,
        totalUnapprovedRecordsCount: action.payload.unapproved_mappings_count,
      };
    case LowConfidenceScoreActionTypes.FetchLowConfidenceScoresCountFailed:
      return {
        ...state,
        totalUnapprovedRecordsCount: 0,
      };
    case LowConfidenceScoreActionTypes.ResetMessages:
      return {
        ...state,
        notification: null,
        errorMessage: null,
      };
    case LowConfidenceScoreActionTypes.ResetLowConfidenceScoreDetails:
      return {
        ...state,
        selectedRecord: {},
        unapprovedRecords: []
      };
    case LowConfidenceScoreActionTypes.UpdateLowConfidenceScore:
      return {
        ...state,
        isLoading: true,
      };
    case LowConfidenceScoreActionTypes.UpdateLowConfidenceScoreSuccess:
      return {
        ...state,
      };
    case LowConfidenceScoreActionTypes.UpdateLowConfidenceScoreFailed:
      return {
        ...state,
        isLoading: false,
      };
    case LowConfidenceScoreActionTypes.UpdateLowConfidenceScoresMany:
      return {
        ...state,
        isLoading: true,
      };
    case LowConfidenceScoreActionTypes.UpdateLowConfidenceScoresManySuccess:
      return {
        ...state,
      };
    case LowConfidenceScoreActionTypes.UpdateLowConfidenceScoresManyFailed:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
}

export function lowConfidenceScoreFilterReducer(
  state: any = initialFilterState,
  action: LowConfidenceScoreActions
) {
  switch (action.type) {
    case LowConfidenceScoreActionTypes.UpdateLowConfidenceScoresPagination:
      return {
        ...state,
        from: action.payload.from,
        size: action.payload.size,
        sort: action.payload.sort,
      };
    case LowConfidenceScoreActionTypes.UpdateLowConfidenceScoresEntityTypes:
      return {
        ...state,
        entity_type: action.payload.entity_type,
      };
    case LowConfidenceScoreActionTypes.UpdateLowConfidenceScoresSearchTerm:
      return {
        ...state,
        query: action.payload.query,
      };
    case LowConfidenceScoreActionTypes.ResetLowConfidenceScoresFilter:
      return {
        ...initialFilterState,
      };
    case LowConfidenceScoreActionTypes.UpdateLowConfidenceScoresIgnored:
      return {
        ...state,
        ignored: action.payload.ignored,
        selectedIgnoredStates: action.payload.selectedIgnoredStates,
      };
    default:
      return state;
  }
}