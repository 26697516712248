import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { LogoutUser } from '../store/actions';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor(private store: Store) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const tokenData = JSON.parse(localStorage.getItem('oAuth'));
    const userTokens = tokenData && tokenData.user ? tokenData.user.stsTokenManager: tokenData;
    if (tokenData) {
      let modifiedReq = request.clone({
        headers: request.headers
          .set('Authorization', tokenData.idToken)
          .set('oauthAccessToken', tokenData.credential.accessToken)
          .set('expirationTime', JSON.stringify(userTokens.expirationTime))
          .set('Cache-Control', 'no-cache')
          .set('Pragma', 'no-cache'),
      });
      if (request.body && request.body.upload_to_s3) {
        delete request.body.upload_to_s3;
        modifiedReq = modifiedReq.clone({
          headers: request.headers.delete('Authorization'),
        });
      }
      let now = new Date().getTime();
      if(now >= userTokens.expirationTime){
        const payload = {
          message: 'Session timed out. Please login!'
        }
        this.store.dispatch(new LogoutUser(payload));
        return next.handle(request);
      } else {
        return next.handle(modifiedReq);
      }
    } else {
      return next.handle(request);
    }
  }
}
