import { ILowConfidenceScoreFilterState, ILowConfidenceScoreState } from 'src/app/models';
import {
  LowConfidenceScoreDetailsActions,
  LowConfidenceScoreDetailsActionTypes,
} from '../actions/low.confidence.score.details.actions';
import { 
  ILowConfidenceScoreDetailsFilterState, 
  ILowConfidenceScoreDetailsState 
} from 'src/app/models/low.confidence.score.details';

const initialState: ILowConfidenceScoreDetailsState = {
  selectedLowConfidenceScore: null,
  authoritativeRecords: null,
  isLoading: false
};

const initialFilterState: ILowConfidenceScoreDetailsFilterState = {
  authoritativeRecordIds: [],
  from: 0,
  size: 10,
  query: '',
  sort: ''
};

export function lowConfidenceScoreDetailsReducer(
  state: ILowConfidenceScoreDetailsState = initialState,
  action: LowConfidenceScoreDetailsActions
) {
  switch (action.type) {
    case LowConfidenceScoreDetailsActionTypes.FetchLowConfidenceScoreById:
      return {
        ...state,
        isLoading: true,
        authoritativeRecords: null,
      };
    case LowConfidenceScoreDetailsActionTypes.FetchLowConfidenceScoreByIdSuccess:
      return {
        ...state,
        selectedLowConfidenceScore: action.payload,
        isLoading: false,
      };
    case LowConfidenceScoreDetailsActionTypes.FetchLowConfidenceAuthoritativeRecords:
      return {
        ...state,
        isLoading: true,
      };
    case LowConfidenceScoreDetailsActionTypes.FetchLowConfidenceAuthoritativeRecordsSuccess:
      return {
        ...state,
        authoritativeRecords: action.payload.authoritative_records,
        isLoading: false,
      };
    case LowConfidenceScoreDetailsActionTypes.FetchLowConfidenceAuthoritativeRecordsFailed:
      return {
        ...state,
        isLoading: false,
      };
    case LowConfidenceScoreDetailsActionTypes.MoveLowConfidenceScore:
      return {
        ...state,
        isLoading: true,
      };
    case LowConfidenceScoreDetailsActionTypes.MoveLowConfidenceScoreSuccess:
      return {
        ...state,
        isLoading: false,
      };
    case LowConfidenceScoreDetailsActionTypes.MoveLowConfidenceScoreFailed:
      return {
        ...state,
        isLoading: false,
      };
    case LowConfidenceScoreDetailsActionTypes.ApproveLowConfidenceScore:
      return {
        ...state,
        isLoading: true,
      };
    case LowConfidenceScoreDetailsActionTypes.ApproveLowConfidenceScoreSuccess:
      return {
        ...state,
        isLoading: false,
      };
    case LowConfidenceScoreDetailsActionTypes.ApproveLowConfidenceScoreFailed:
      return {
        ...state,
        isLoading: false,
      };
    case LowConfidenceScoreDetailsActionTypes.DeleteLowConfidenceScore:
      return {
        ...state,
        isLoading: true,
      };
    case LowConfidenceScoreDetailsActionTypes.DeleteLowConfidenceScoreSuccess:
      return {
        ...state,
        isLoading: false,
      };
    case LowConfidenceScoreDetailsActionTypes.DeleteLowConfidenceScoreFailed:
      return {
        ...state,
        isLoading: false,
      };
    case LowConfidenceScoreDetailsActionTypes.IgnoreLowConfidenceScore:
      return {
        ...state,
        isLoading: true,
      };
    case LowConfidenceScoreDetailsActionTypes.IgnoreLowConfidenceScoreSuccess:
      return {
        ...state,
        isLoading: false,
        ignored: true,
      };
    case LowConfidenceScoreDetailsActionTypes.IgnoreLowConfidenceScoreFailed:
      return {
        ...state,
        isLoading: false,
      };
    case LowConfidenceScoreDetailsActionTypes.MakeLowConfidenceScoreActive:
      return {
        ...state,
        isLoading: true,
      };
    case LowConfidenceScoreDetailsActionTypes.MakeLowConfidenceScoreActiveSuccess:
      return {
        ...state,
        isLoading: false,
        ignored: false
      };
    case LowConfidenceScoreDetailsActionTypes.MakeLowConfidenceScoreActiveFailed:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}

export function lowConfidenceScoreDetailsFilterReducer(
  state: any = initialFilterState,
  action: LowConfidenceScoreDetailsActions
) {
  switch (action.type) {
    case LowConfidenceScoreDetailsActionTypes.UpdateLowConfidenceDetailsSearchQuery:
      return {
        ...state,
        from: action.payload.from,
        query: action.payload.query
      };
    case LowConfidenceScoreDetailsActionTypes.UpdateLowConfidenceDetailsPagination:
      return {
        ...state,
        from: action.payload.from,
        size: action.payload.size,
        sort: action.payload.sort,
      };
    case LowConfidenceScoreDetailsActionTypes.UpdateLowConfidenceScoreAuthtRecords:
      return {
        ...state,
        authoritativeRecordIds: action.authoritativeRecordIds
      };
    case LowConfidenceScoreDetailsActionTypes.ResetLowConfidenceScoreDetailsFilters:
      return {
        ...state,
        authoritativeRecordIds: [],
        query: '',
        from: 0,
        size: 50,
        sort: '',
      };
    default:
      return state;
  }
}