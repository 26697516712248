import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Location } from '@angular/common';
import { from } from 'rxjs';
import { CommonService } from './common.service';
import { IPayload } from '../models';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService extends CommonService {
  constructor(private http: HttpClient, public loc: Location) {
    super(loc);
  }

  // function to fetch authoritative records list from server
  fetchNotifications(payload: any) {
    let body: IPayload = {
      from: payload.from,
      size: payload.size,
      type: payload.type,
      state: payload.state,
      newRecordType: payload.newRecordType,
      changeType: payload.changeType,
      query: payload.query,
    };
    if (payload && payload.sort && payload.sort !== '') {
      const sortArr = payload.sort.split(':');
      const order = sortArr[1] ? sortArr[1] : 'asc';
      sortArr[0] === 'updated' ? (sortArr[0] = `${sortArr[0]}`) : null;
      body['sort'] = `${sortArr[0]}:${order}`;
    }

    return from(
      this.http
        .get<any>(
          `${this.baseUrl}/${this.environment.service.apiStage}/notifications`,
          { params: body }
        )
        .pipe(
          catchError(this.handleError)
        )
    );
  }

  /*
    Function: fetchNotificationsCount
    Purpose: Get the total count of unacknowledged notifications (ie - notifications that are in a pending state)
  */
  fetchNotificationsCount() {
    return from(
      this.http
        .get<any>(
          `${this.baseUrl}/${this.environment.service.apiStage}/notifications/count`
        )
        .pipe(
          catchError(this.handleError)
        )
    );
  }

  fetchNotification(payload: any) {
    return from(
      this.http
        .get<any>(
          `${this.baseUrl}/${this.environment.service.apiStage}/notifications/${payload.id}`
        )
        .pipe(
          catchError(this.handleError)
        )
    );
  }

  updateNotification(payload: any) {
    return from(
      this.http
        .patch<any>(
          `${this.baseUrl}/${this.environment.service.apiStage}/notifications/${payload.id}`,
          { 
            state: payload.state,
            updatedBy: JSON.parse(localStorage.getItem('user')).displayName
          }
        )
        .pipe(
          catchError(this.handleError)
        )
    );
  }
}
