import { Component, OnInit } from '@angular/core';
import { ILanguage } from 'src/app/models';
import { GetAppVersionData, SetLanguage } from 'src/app/store/actions';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-localize',
  templateUrl: './localize.component.html',
  styleUrls: ['./localize.component.css'],
})
export class LocalizeComponent implements OnInit {
  languages: ILanguage[] = [
    {
      language: 'English',
      code: 'en',
    },
    {
      language: 'Test Language',
      code: 'tl',
    },
  ];
  selectedLanguage: ILanguage = {
    language: 'English',
    code: 'en',
  };
  constructor(public store: Store, public translate: TranslateService) {}

  ngOnInit(): void {
    const lang = localStorage.getItem('language')
      ? JSON.parse(localStorage.getItem('language'))
      : undefined;

    // Set the default language if language is not set
    if (lang) {
      this.setLanguage(lang);
    } else {
      this.setLanguage(this.selectedLanguage);
    }
  }

  // set new language
  setLanguage(lang: ILanguage) {
    const langs = this.languages.map((l) => l.code);
    this.translate.addLangs(langs);
    this.selectedLanguage = lang;
    this.translate.setDefaultLang(lang?.code);
    this.translate.use(lang?.code);
    this.store.dispatch(new SetLanguage(lang));
    this.store.dispatch(new GetAppVersionData());
  }
}
