import { ICommon } from "src/app/models";
import { CommonActionTypes, CommonActions } from "../actions/common.actions";

const initialLanguageState: ICommon = {
    language: "English",
    code: "en",
    appVersionData: null
}

export function commonReducer(state = initialLanguageState, action: CommonActions): any {
    switch (action.type) {
        case CommonActionTypes.SetLanguage:
            localStorage.setItem('language', JSON.stringify(action.payload));
            return {
                ...action.payload
            }

        case CommonActionTypes.GetAppVersionData:
            return {
                ...state
            }

        case CommonActionTypes.GetAppVersionDataSuccess:
            return {
                ...state,
                appVersionData: action.payload
            }

        case CommonActionTypes.GetAppVersionDataFailed:
            return {
                ...state
            }
    }

}