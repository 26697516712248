import { Injectable } from '@angular/core';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { map, catchError, switchMap, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  FetchSynchronizeStatus,
  FetchSynchronizeStatusFailed,
  FetchSynchronizeStatusSuccess,
  SynchronizeActionTypes,
  SynchronizeData,
  SynchronizeDataFailed,
  SynchronizeDataSuccess,
} from '../actions';
import { SynchronizeService } from 'src/app/services/synchronize.service';
import { FetchUploadStatusSuccess } from '../actions/import.export.actions';

@Injectable()
export class SynchronizeEffects {
  constructor(
    private actions$: Actions,
    private synchronizService: SynchronizeService
  ) {}

  @Effect()
  synchronizeData$ = this.actions$.pipe(
    ofType(SynchronizeActionTypes.SynchronizeData),
    switchMap((action: SynchronizeData) =>
      this.synchronizService.syncOpenSearch().pipe(
        mergeMap((response: any) => {
          return [
            new SynchronizeDataSuccess(response),
            new FetchSynchronizeStatus({ loading: false,  isInProgress: true }),
          ];
        }),
        catchError((error) =>
          of(
            new SynchronizeDataFailed({
              message: error.error
                ? error.error
                : error.message
                ? error.message
                : error,
            })
          )
        )
      )
    )
  );

  @Effect()
  fetchSynStatus$ = this.actions$.pipe(
    ofType(SynchronizeActionTypes.FetchSynchronizeStatus),
    switchMap((action: FetchSynchronizeStatus) =>
      this.synchronizService.fetchSynchronizationStatus().pipe(
        map((response: any) => new FetchSynchronizeStatusSuccess(response)),
        catchError((error) =>
          of(
            new FetchSynchronizeStatusFailed({
              message: error.error
                ? error.error
                : error.message
                ? error.message
                : error,
            })
          )
        )
      )
    )
  );
}
