import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { LoginUser } from '../../store/actions';
import { IAppState } from 'src/app/models';
import { getUser, getLoginError } from '../../store';
import { Observable } from 'rxjs';
import { IMessage } from 'src/app/models';
import { PrimeNGConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeComponent } from '../localize/localize.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  user$: Observable<any>;
  error: IMessage[];

  constructor(
    private store: Store<IAppState>,
    private primeNgConfig: PrimeNGConfig,
    private translate: TranslateService
  ) {
    this.primeNgConfig.ripple = true;
  }

  ngOnInit(): void {
    this.user$ = this.store.pipe(select(getUser));
    this.store.select(getLoginError).subscribe((error) => {
      if (error) {
        // Translate the error message
        this.translate
          .get('Error.Message', { value: error })
          .subscribe((errorMessage) => {
            this.error = [
              {
                severity: 'error',
                summary: 'Error',
                detail: errorMessage,
                life: 4000,
              },
            ];
            setTimeout(() => {
              this.error = [];
            }, 5000);
          });
      }
    });
  }

  // Login with Google
  login() {
    this.store.dispatch(new LoginUser());
  }
}
