import { Action } from "@ngrx/store";

export enum CommonActionTypes {
    SetLanguage = '[Common] Set Language',

    GetAppVersionData = '[Common] Get Application Version Data',
    GetAppVersionDataSuccess = '[Common] Get Application Version Data Success',
    GetAppVersionDataFailed = '[Common] Get Application Version Data Failed'
}


export class SetLanguage implements Action {
    readonly type = CommonActionTypes.SetLanguage;
    constructor(public payload?: any) { }
}

export class GetAppVersionData implements Action {
    readonly type = CommonActionTypes.GetAppVersionData;
}

export class GetAppVersionDataSuccess implements Action {
    readonly type = CommonActionTypes.GetAppVersionDataSuccess;
    constructor(public payload: any) { }
}

export class GetAppVersionDataFailed implements Action {
    readonly type = CommonActionTypes.GetAppVersionDataFailed;
    constructor(public payload: any) { }
}

export type CommonActions = SetLanguage | GetAppVersionData | GetAppVersionDataSuccess | GetAppVersionDataFailed;