import { Injectable } from '@angular/core';
import { Actions, ofType, Effect, act } from '@ngrx/effects';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { AuthoritativeRecordsService } from 'src/app/services/authoritative.records.service';

import {
  FetchAuthoritativeRecordById,
  FetchAuthoritativeRecordByIdSuccess,
  FetchAuthoritativeRecordByIdFailed,
  AuthoritativeRecordDetailsActionTypes,
  FetchInputMappingsByAuthoritativeRecordIdSuccess,
  FetchInputMappingsByAuthoritativeRecordIdFailed,
  DeleteInputMappingRecord,
  DeleteInputMappingRecordSuccess,
  DeleteInputMappingRecordFailed,
  FetchInputMappingsByAuthoritativeRecordId,
} from '../actions';

@Injectable()
export class AuthoritativeRecordDetailsEffects {
  params: any;
  constructor(
    private actions$: Actions,
    private authoritativeRecordsService: AuthoritativeRecordsService,
  ) {}

  @Effect()
  fetchAuthoritativeRecordById$ = this.actions$.pipe(
    ofType(AuthoritativeRecordDetailsActionTypes.FetchAuthoritativeRecordById),
    switchMap((action: FetchAuthoritativeRecordById) =>
      this.authoritativeRecordsService.fetchAuthoritativeRecordById(action.id).pipe(
        map((response: any) => new FetchAuthoritativeRecordByIdSuccess(response)),
        catchError((error) =>
          of(
            new FetchAuthoritativeRecordByIdFailed({
              message: error.error
                ? error.error
                : error.message
                ? error.message
                : error,
            })
          )
        )
      )
    )
  );

  @Effect()
  fetchInputMappingsByAuthoritativeRecordId$ = this.actions$.pipe(
    ofType(AuthoritativeRecordDetailsActionTypes.FetchInputMappingsByAuthoritativeRecordId),
    switchMap((action: any) =>
      this.authoritativeRecordsService.fetchInputMappingsByAuthoritativeRecordId(action.payload).pipe(
        map((response: any) => new FetchInputMappingsByAuthoritativeRecordIdSuccess(response)),
        catchError((error) =>
          of(
            new FetchInputMappingsByAuthoritativeRecordIdFailed({
              message: error.error
                ? error.error
                : error.message
                ? error.message
                : error,
            })
          )
        )
      )
    )
  );

  @Effect()
  fetchInputMappings$ = this.actions$.pipe(
    ofType(AuthoritativeRecordDetailsActionTypes.FetchInputMappingsByAuthoritativeRecordId),
    switchMap((action: FetchInputMappingsByAuthoritativeRecordId) =>
      this.authoritativeRecordsService
        .fetchInputMappingsByAuthoritativeRecordId(action.payload)
        .pipe(
          map(
            (response: any) =>
              new FetchInputMappingsByAuthoritativeRecordIdSuccess(response)
          ),
          catchError((error) =>
            of(new FetchInputMappingsByAuthoritativeRecordIdFailed(error))
          )
        )
    )
  );

  @Effect()
  deleteInputMapping$ = this.actions$.pipe(
    ofType(AuthoritativeRecordDetailsActionTypes.DeleteInputMapping),
    switchMap((action: DeleteInputMappingRecord) =>
      this.authoritativeRecordsService.deleteInputRecordMapping(action.payload).pipe(
        map(
          (response: any) => new DeleteInputMappingRecordSuccess(action.payload)
        ),
        catchError((error) =>
          of(
            new DeleteInputMappingRecordFailed({
              message: error.error
                ? error.error
                : error.message
                ? error.message
                : error,
            })
          )
        )
      )
    )
  );
}
