import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { PrimeNGConfig } from 'primeng/api';
import { LogoutUser } from '../../../store/actions';
import { LocalizeComponent } from '../../localize/localize.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  userData:any

  constructor(private store: Store, private primeNGConfig:PrimeNGConfig) { 
    this.primeNGConfig.ripple = true;
  }

  ngOnInit() :void {
    // get user data from selector
    this.userData = JSON.parse(localStorage.getItem('user'));
  }

  // Function to sign out the user
  logout(): void {
    this.store.dispatch(new LogoutUser({}));
  }
}

