import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { PaginatorModule } from 'primeng/paginator';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ConfirmationService } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AuthoritativeRecordsRoutingModule } from './authoritative.records-routing.module';
import { AppSharedModule } from 'src/app/app-shared/app-shared.module';
import { AuthoritativeRecordDetailsComponent } from '../authoritative.record.details/authoritative.record.details.component';
import { AuthoritativeRecordsComponent } from './authoritative.records.component';
import { MultiSelectModule } from 'primeng/multiselect';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../../../assets/i18n', '.json');
}
@NgModule({
  declarations: [AuthoritativeRecordDetailsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    PaginatorModule,
    CardModule,
    DialogModule,
    ConfirmDialogModule,
    ConfirmPopupModule,
    MessagesModule,
    MessageModule,
    ToastModule,
    MultiSelectModule,
    AuthoritativeRecordsRoutingModule,
    AppSharedModule,
    HttpClientModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    }),
    TableModule,
    ButtonModule,
  ],
  exports: [TranslateModule],
  providers: [ConfirmationService, MessageService],
})
export class DashboardModule {}
