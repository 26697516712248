import {
  IAuthoritativeRecordDetailsState,
  IAuthoritativeRecordDetailsFilterState,
} from 'src/app/models';

import {
  AuthoritativeRecordDetailsActionTypes,
  AuthoritativeRecordDetailsActions,
} from '../actions/authoritative.record.details.actions';

const initialAuthoritativeRecordDetailsState: IAuthoritativeRecordDetailsState = {
  authoritativeRecordDetails: null,
  updatingActivatedState: false,
  inputRecords: [],
  totalInputRecords: 0,
  isLoadingInputRecords: false,
  isLoadingDetails: false,
};

export function authoritativeRecordDetailsReducer(
  state: any = initialAuthoritativeRecordDetailsState,
  action: AuthoritativeRecordDetailsActions
) {
  switch (action.type) {
    case AuthoritativeRecordDetailsActionTypes.FetchAuthoritativeRecordById:
      return {
        ...state,
        isLoading: true,
      };
    case AuthoritativeRecordDetailsActionTypes.FetchAuthoritativeRecordByIdSuccess:
      return {
        ...state,
        isLoading: false,
        authoritativeRecordDetails: action.payload,
      };
    case AuthoritativeRecordDetailsActionTypes.FetchAuthoritativeRecordByIdFailed:
      return {
        ...state,
        isLoading: false,
      };
    case AuthoritativeRecordDetailsActionTypes.FetchInputMappingsByAuthoritativeRecordId:
      return {
        ...state,
        isLoadingInputRecords: true,
      };
    case AuthoritativeRecordDetailsActionTypes.FetchInputMappingsByAuthoritativeRecordIdSuccess:
      return {
        ...state,
        isLoadingInputRecords: false,
        inputRecords: action.payload.mappings,
        totalInputRecords: action.payload.count,
      };
    case AuthoritativeRecordDetailsActionTypes.FetchInputMappingsByAuthoritativeRecordIdFailed:
      return {
        ...state,
        isLoadingInputRecords: false,
      };
    case AuthoritativeRecordDetailsActionTypes.DeleteInputMapping:
      return {
        ...state,
        isLoadingInputRecords: true,
      };
    case AuthoritativeRecordDetailsActionTypes.DeleteInputMappingFailed:
      return {
        ...state,
        isLoadingInputRecords: false,
      }
    case AuthoritativeRecordDetailsActionTypes.UpdateAuthoritativeRecord:
      return {
        ...state,
        updatingActivatedState: true,
      };
    case AuthoritativeRecordDetailsActionTypes.UpdateAuthoritativeRecordSuccess:
      return {
        ...state,
        updatingActivatedState: false,
      };
    case AuthoritativeRecordDetailsActionTypes.UpdateAuthoritativeRecordFailed:
      return {
        ...state,
        updatingActivatedState: false,
      };
    default:
      return state;
  }
}

const initialAuthoritativeRecordDetailsFilterState: IAuthoritativeRecordDetailsFilterState = {
  from: 0,
  size: 10,
  sort: "",
}

export function authoritativeRecordDetailsFilterReducer(
  state: any = initialAuthoritativeRecordDetailsFilterState,
  action: AuthoritativeRecordDetailsActions
) {
  switch (action.type) {
    case AuthoritativeRecordDetailsActionTypes.UpdateAuthoritativeRecordDetailsPagination:
      return {
        ...state,
        from: action.payload.from,
        size: action.payload.size,
        sort: action.payload.sort,
      };
    default:
      return state;
  }
}