import { Action } from '@ngrx/store';

export enum SynchronizeActionTypes {
  SynchronizeData = '[Synchronize] Synchronize The Database',
  SynchronizeDataSuccess = '[Synchronize] Synchronize The Database Success',
  SynchronizeDataFailed = '[Synchronize] Synchronize The Database Failed',

  FetchSynchronizeStatus = '[Synchronize] Fetch The Synchronize Status',
  FetchSynchronizeStatusSuccess = '[Synchronize] Fetch The Synchronize Status Success',
  FetchSynchronizeStatusFailed = '[Synchronize] Fetch The Synchronize Status Failed',
}

export class SynchronizeData implements Action {
  readonly type = SynchronizeActionTypes.SynchronizeData;
}

export class SynchronizeDataSuccess implements Action {
  readonly type = SynchronizeActionTypes.SynchronizeDataSuccess;
  constructor(public payload: any) {}
}

export class SynchronizeDataFailed implements Action {
  readonly type = SynchronizeActionTypes.SynchronizeDataFailed;
  constructor(public payload: any) {}
}

export class FetchSynchronizeStatus implements Action {
  readonly type = SynchronizeActionTypes.FetchSynchronizeStatus;
  constructor(public payload: any) {}
}

export class FetchSynchronizeStatusSuccess implements Action {
  readonly type = SynchronizeActionTypes.FetchSynchronizeStatusSuccess;
  constructor(public payload: any) {}
}

export class FetchSynchronizeStatusFailed implements Action {
  readonly type = SynchronizeActionTypes.FetchSynchronizeStatusFailed;
  constructor(public payload: any) {}
}

export type SynchronizeActions =
  | SynchronizeData
  | SynchronizeDataSuccess
  | SynchronizeDataFailed
  | FetchSynchronizeStatus
  | FetchSynchronizeStatusSuccess
  | FetchSynchronizeStatusFailed;
