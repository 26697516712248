import { ISynchronizeState } from 'src/app/models';
import { SynchronizeActionTypes, SynchronizeActions } from '../actions';
import { ISettingsState } from 'src/app/models/settings';
import { SettingsActionTypes, SettingsActions } from '../actions/settings.actions';

const initialState: ISettingsState = {
  isSyncing: false,
  isRemapping: false
};

export function settingsReducer(
  state: ISettingsState = initialState,
  action: SettingsActions
) {
  switch (action.type) {
    case SettingsActionTypes.Sync:
      return {
        ...state,
        isSyncing: true
      };
    case SettingsActionTypes.SyncSuccess:
      return { 
        ...state,
        isSyncing: true
      };
    case SettingsActionTypes.SyncFailed:
      return { 
        ...state,
        isSyncing: false
      };
    case SettingsActionTypes.SyncStatusSuccess:
      return { 
        ...state,
        isSyncing: action.payload.authtRecordsAreSyncing || action.payload.notificationsAreSyncing ? true : false
      };
    case SettingsActionTypes.Remap:
      return {
        ...state,
        isRemapping: true
      };
    case SettingsActionTypes.RemapSuccess:
      return { 
        ...state,
        isRemapping: true
      };
    case SettingsActionTypes.RemapFailed:
      return { 
        ...state,
        isRemapping: false
      };
    case SettingsActionTypes.CheckRemapStatusSuccess:
      return { 
        ...state,
        isRemapping: action.payload.num_records > 0 ? true : false
      };
    default:
      return state;
  }
}
