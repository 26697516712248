import { Action } from '@ngrx/store';

export enum LowConfidenceScoreDetailsActionTypes {

  FetchLowConfidenceScoreById = '[Low Confidence Score Details Page] Fetch Low Confidence Score By Id',
  FetchLowConfidenceScoreByIdSuccess = '[Low Confidence Score Details Page] Fetch Low Confidence Score By Id Success',
  FetchLowConfidenceScoreByIdFailed = '[Low Confidence Score Details Page] Fetch Low Confidence Score By Id Failed',

  FetchLowConfidenceAuthoritativeRecords = '[Low Confidence Score Details Page] Fetch Low Confidence Authoritative Records',
  FetchLowConfidenceAuthoritativeRecordsSuccess = '[Low Confidence Score Details Page] Fetch Low Confidence Authoritative Records Success',
  FetchLowConfidenceAuthoritativeRecordsFailed = '[Low Confidence Score Details Page] Fetch Low Confidence Authoritative Records Failed',

  MoveLowConfidenceScore = '[Low Confidence Score Details Page] Move Low Confidence Score',
  MoveLowConfidenceScoreSuccess = '[Low Confidence Score Details Page] Move Low Confidence Score Success',
  MoveLowConfidenceScoreFailed = '[Low Confidence Score Details Page] Move Low Confidence Score Failed',

  ApproveLowConfidenceScore = '[Low Confidence Score Details Page] Approve Low Confidence Score',
  ApproveLowConfidenceScoreSuccess = '[Low Confidence Score Details Page] Approve Low Confidence Score Success',
  ApproveLowConfidenceScoreFailed = '[Low Confidence Score Details Page] Approve Low Confidence Score Failed',

  DeleteLowConfidenceScore = '[Low Confidence Score Details Page] Delete Low Confidence Score',
  DeleteLowConfidenceScoreSuccess = '[Low Confidence Score Details Page] Delete Low Confidence Score Success',
  DeleteLowConfidenceScoreFailed = '[Low Confidence Score Details Page] Delete Low Confidence Score Failed',

  IgnoreLowConfidenceScore = '[Low Confidence Score Details Page] Ignore Low Confidence Score',
  IgnoreLowConfidenceScoreSuccess = '[Low Confidence Score Details Page] Ignore Low Confidence Score Success',
  IgnoreLowConfidenceScoreFailed = '[Low Confidence Score Details Page] Ignore Low Confidence Score Failed',

  MakeLowConfidenceScoreActive = '[Low Confidence Score Details Page] Make Low Confidence Score Active',
  MakeLowConfidenceScoreActiveSuccess = '[Low Confidence Score Details Page] Make Low Confidence Score Active Success',
  MakeLowConfidenceScoreActiveFailed = '[Low Confidence Score Details Page] Make Low Confidence Score Active Failed',

  UpdateLowConfidenceDetailsSearchQuery = '[Low Confidence Score Details Page] Update Low Confidence Details Search Query',
  UpdateLowConfidenceDetailsPagination = '[Low Confidence Score Details Page] Update Low Confidence Details Pagination',
  UpdateLowConfidenceScoreAuthtRecords = '[Low Confidence Score Details Page] Update Low Confidence Score Authoritative Records',
  ResetLowConfidenceScoreDetailsFilters = '[Low Confidence Score Details Page] Reset Low Confidence Score Details Filters',

  ResetLowConfidenceScoreDetails = "[Low Confidence Score Details] Reset Low Confidence Score Details Data"
}

export class FetchLowConfidenceScoreById implements Action {
  readonly type = LowConfidenceScoreDetailsActionTypes.FetchLowConfidenceScoreById;
  constructor(public id: string) {}
}

export class FetchLowConfidenceScoreByIdSuccess implements Action {
  readonly type = LowConfidenceScoreDetailsActionTypes.FetchLowConfidenceScoreByIdSuccess;
  constructor(public payload: any) {}
}

export class FetchLowConfidenceScoreByIdFailed implements Action {
  readonly type = LowConfidenceScoreDetailsActionTypes.FetchLowConfidenceScoreByIdFailed;
  constructor(public payload: any) {}
}

export class FetchLowConfidenceAuthoritativeRecords implements Action {
  readonly type = LowConfidenceScoreDetailsActionTypes.FetchLowConfidenceAuthoritativeRecords;
  constructor(public payload: any) {}
}

export class FetchLowConfidenceAuthoritativeRecordsSuccess implements Action {
  readonly type = LowConfidenceScoreDetailsActionTypes.FetchLowConfidenceAuthoritativeRecordsSuccess;
  constructor(public payload: any) {}
}

export class FetchLowConfidenceAuthoritativeRecordsFailed implements Action {
  readonly type = LowConfidenceScoreDetailsActionTypes.FetchLowConfidenceAuthoritativeRecordsFailed;
  constructor(public payload: any) {}
}

export class MoveLowConfidenceScore implements Action {
  readonly type = LowConfidenceScoreDetailsActionTypes.MoveLowConfidenceScore;
  constructor(public payload: any) {}
}

export class MoveLowConfidenceScoreSuccess implements Action {
  readonly type = LowConfidenceScoreDetailsActionTypes.MoveLowConfidenceScoreSuccess;
  constructor(public payload: any) {}
}

export class MoveLowConfidenceScoreFailed implements Action {
  readonly type = LowConfidenceScoreDetailsActionTypes.MoveLowConfidenceScoreFailed;
  constructor(public payload: any) {}
}

export class ApproveLowConfidenceScore implements Action {
  readonly type = LowConfidenceScoreDetailsActionTypes.ApproveLowConfidenceScore;
  constructor(public payload: any) {}
}

export class ApproveLowConfidenceScoreSuccess implements Action {
  readonly type = LowConfidenceScoreDetailsActionTypes.ApproveLowConfidenceScoreSuccess;
  constructor(public payload: any) {}
}

export class ApproveLowConfidenceScoreFailed implements Action {
  readonly type = LowConfidenceScoreDetailsActionTypes.ApproveLowConfidenceScoreFailed;
  constructor(public payload: any) {}
}

export class DeleteLowConfidenceScore implements Action {
  readonly type = LowConfidenceScoreDetailsActionTypes.DeleteLowConfidenceScore;
  constructor(public payload: any) {}
}

export class DeleteLowConfidenceScoreSuccess implements Action {
  readonly type = LowConfidenceScoreDetailsActionTypes.DeleteLowConfidenceScoreSuccess;
  constructor(public payload: any) {}
}

export class DeleteLowConfidenceScoreFailed implements Action {
  readonly type = LowConfidenceScoreDetailsActionTypes.DeleteLowConfidenceScoreFailed;
  constructor(public payload: any) {}
}

export class IgnoreLowConfidenceScore implements Action {
  readonly type = LowConfidenceScoreDetailsActionTypes.IgnoreLowConfidenceScore;
  constructor(public payload: any) {}
}

export class IgnoreLowConfidenceScoreSuccess implements Action {
  readonly type = LowConfidenceScoreDetailsActionTypes.IgnoreLowConfidenceScoreSuccess;
  constructor(public payload: any) {}
}

export class IgnoreLowConfidenceScoreFailed implements Action {
  readonly type = LowConfidenceScoreDetailsActionTypes.IgnoreLowConfidenceScoreFailed;
  constructor(public payload: any) {}
}

export class MakeLowConfidenceScoreActive implements Action {
  readonly type = LowConfidenceScoreDetailsActionTypes.MakeLowConfidenceScoreActive;
  constructor(public payload: any) {}
}

export class MakeLowConfidenceScoreActiveSuccess implements Action {
  readonly type = LowConfidenceScoreDetailsActionTypes.MakeLowConfidenceScoreActiveSuccess;
  constructor(public payload: any) {}
}

export class MakeLowConfidenceScoreActiveFailed implements Action {
  readonly type = LowConfidenceScoreDetailsActionTypes.MakeLowConfidenceScoreActiveFailed;
  constructor(public payload: any) {}
}

export class UpdateLowConfidenceDetailsSearchQuery implements Action {
  readonly type = LowConfidenceScoreDetailsActionTypes.UpdateLowConfidenceDetailsSearchQuery;
  constructor(public payload: any) {}
}

export class UpdateLowConfidenceDetailsPagination implements Action {
  readonly type = LowConfidenceScoreDetailsActionTypes.UpdateLowConfidenceDetailsPagination;
  constructor(public payload: any) {}
}

export class UpdateLowConfidenceScoreAuthtRecords implements Action {
  readonly type = LowConfidenceScoreDetailsActionTypes.UpdateLowConfidenceScoreAuthtRecords;
  constructor(public authoritativeRecordIds: string[]) {}
}

export class ResetLowConfidenceScoreDetailsFilters implements Action {
  readonly type = LowConfidenceScoreDetailsActionTypes.ResetLowConfidenceScoreDetailsFilters;
}

export class ResetLowConfidenceScoreDetails implements Action {
  readonly type = LowConfidenceScoreDetailsActionTypes.ResetLowConfidenceScoreDetails
}

export type LowConfidenceScoreDetailsActions =
  | ResetLowConfidenceScoreDetails
  | FetchLowConfidenceScoreById
  | FetchLowConfidenceScoreByIdSuccess
  | FetchLowConfidenceScoreByIdFailed
  | FetchLowConfidenceAuthoritativeRecords
  | FetchLowConfidenceAuthoritativeRecordsSuccess
  | FetchLowConfidenceAuthoritativeRecordsFailed
  | MoveLowConfidenceScore
  | MoveLowConfidenceScoreSuccess
  | MoveLowConfidenceScoreFailed
  | ApproveLowConfidenceScore
  | ApproveLowConfidenceScoreSuccess
  | ApproveLowConfidenceScoreFailed
  | DeleteLowConfidenceScore
  | DeleteLowConfidenceScoreSuccess
  | DeleteLowConfidenceScoreFailed
  | IgnoreLowConfidenceScore
  | IgnoreLowConfidenceScoreSuccess
  | IgnoreLowConfidenceScoreFailed
  | MakeLowConfidenceScoreActive
  | MakeLowConfidenceScoreActiveSuccess
  | MakeLowConfidenceScoreActiveFailed
  | UpdateLowConfidenceDetailsSearchQuery
  | UpdateLowConfidenceDetailsPagination
  | UpdateLowConfidenceScoreAuthtRecords
  | ResetLowConfidenceScoreDetailsFilters;