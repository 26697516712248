import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Location } from '@angular/common';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class ImportExportService extends CommonService {
  constructor(private http: HttpClient, public loc: Location) {
    super(loc);
  }

  // Export file
  exportFile(payload: any) {
    return from(
      this.http
        .get<any>(
          `${this.baseUrl}/${this.environment.service.apiStage}/export-data?password=${payload.password}`
        )
        .pipe(
          map((response) => response),
          catchError(this.handleError)
        )
    );
  }

  // Fetch upload url
  fetchUploadUrl(payload:any) {
    const body = { file_name: payload.file.name }
    return from(
      this.http
        .post<any>(
          `${this.baseUrl}/${this.environment.service.apiStage}/import-data/upload-url`,
           body
        )
        .pipe(
          map((response: any) => {
            return response;
          }),
          catchError(this.handleError)
        )
    );
  }

  importDb(payload: any) {
    return from(
      this.http
        .post<any>(
          `${this.baseUrl}/${this.environment.service.apiStage}/import-data`,
          payload
        )
        .pipe(
          map((response: any) => {
            return response;
          }),
          catchError(this.handleError)
        )
    );
  }

  // upload file
  uploadFile(url: string, file: any) {
    file.upload_to_s3 = true
    return from(
      this.http
        .put<any>(`${url}`, file)
        .pipe(
          map((response) => response),
          catchError(this.handleError)
        )
    );
  }

  // get upload status
  fetchUploadStatus(reqId: string) {
    return from(
      this.http
        .get<any>(
          `${this.baseUrl}/${this.environment.service.apiStage}/import-data/status?req_id=${reqId}`
        )
        .pipe(
          map((response) => response),
          catchError(this.handleError)
        )
    );
  }
}
