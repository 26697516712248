import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { LowConfidenceScoreDetailsComponent } from '../low.confidence.score.details/low.confidence.score.details.component';
import { LowConfidenceScoreComponent } from './low.confidence.score.component';

const routes: Routes = [
  {
    path: '',
    component: LowConfidenceScoreComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'confidencescores/:id',
    component: LowConfidenceScoreDetailsComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LowConfidenceScoreRoutingModule {}
