import { Injectable } from '@angular/core';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  LowConfidenceScoreActionTypes,
  FetchLowConfidenceScores,
  FetchLowConfidenceScoresSuccess,
  FetchLowConfidenceScoresFailed,
  ApproveInputRecordMapping,
  ApproveInputRecordMappingSuccess,
  ApproveInputRecordMappingFailed,
  FetchLowConfidenceScoresCount,
  FetchLowConfidenceScoresCountSuccess,
  FetchLowConfidenceScoresCountFailed,
  UpdateLowConfidenceScoresMany,
  UpdateLowConfidenceScoresManySuccess,
  UpdateLowConfidenceScoresManyFailed,
  UpdateLowConfidenceScore,
  UpdateLowConfidenceScoreSuccess,
  UpdateLowConfidenceScoreFailed,
} from '../actions/low.confidence.score.actions';
import { LowConfidenceScoreService } from 'src/app/services/low-confidence-score.service';
import { AuthoritativeRecordsService } from 'src/app/services/authoritative.records.service';

@Injectable()
export class LowConfidenceScoreEffects {
  constructor(
    private actions$: Actions,
    private lowScoreService: LowConfidenceScoreService,
    private authoritativeRecordsService: AuthoritativeRecordsService
  ) {}
  
  @Effect()
  fetchLowConfidenceScores$ = this.actions$.pipe(
    ofType(LowConfidenceScoreActionTypes.FetchLowConfidenceScores),
    switchMap((action: FetchLowConfidenceScores) =>
      this.lowScoreService
        .fetchLowConfidenceScoreList(action.payload)
        .pipe(
          map((response: any) => new FetchLowConfidenceScoresSuccess(response)),
          catchError((error) =>
            of(
              new FetchLowConfidenceScoresFailed({
                message: error.error ? error.error : error.message,
              })
            )
          )
        )
    )
  );

  @Effect()
  updateLowConfidenceScore$ = this.actions$.pipe(
    ofType(LowConfidenceScoreActionTypes.UpdateLowConfidenceScore),
    switchMap((action: UpdateLowConfidenceScore) =>
      this.lowScoreService
        .updateLowConfidenceScore(action.payload)
        .pipe(
          map((response: any) => new UpdateLowConfidenceScoreSuccess(response)),
          catchError((error) =>
            of(
              new UpdateLowConfidenceScoreFailed({
                message: error.error ? error.error : error.message,
              })
            )
          )
        )
    )
  );

  @Effect()
  updateLowConfidenceScoresMany$ = this.actions$.pipe(
    ofType(LowConfidenceScoreActionTypes.UpdateLowConfidenceScoresMany),
    switchMap((action: UpdateLowConfidenceScoresMany) =>
      this.lowScoreService
        .updateLowConfidenceScoresMany(action.payload)
        .pipe(
          map((response: any) => new UpdateLowConfidenceScoresManySuccess(response)),
          catchError((error) =>
            of(
              new UpdateLowConfidenceScoresManyFailed({
                message: error.error ? error.error : error.message,
              })
            )
          )
        )
    )
  );

  @Effect()
  fetchLowConfidenceScoreCount$ = this.actions$.pipe(
    ofType(LowConfidenceScoreActionTypes.FetchLowConfidenceScoresCount),
    switchMap((action: FetchLowConfidenceScoresCount) =>
      this.lowScoreService
        .fetchLowConfidenceScoreCount()
        .pipe(
          map((response: any) => new FetchLowConfidenceScoresCountSuccess(response)),
          catchError((error) =>
            of(
              new FetchLowConfidenceScoresCountFailed({
                message: error.error ? error.error : error.message,
              })
            )
          )
        )
    )
  );

  @Effect()
  approveInputRecordMapping$ = this.actions$.pipe(
    ofType(LowConfidenceScoreActionTypes.ApproveMapping),
    switchMap((action: ApproveInputRecordMapping) =>
      this.authoritativeRecordsService
        .updateInputRecord(action.payload)
        .pipe(
          map((response: any) => {
            return new ApproveInputRecordMappingSuccess(response);
          }),
          catchError((error) =>
            of(
              new ApproveInputRecordMappingFailed({
                message: error.error ? error.error : error.message,
              })
            )
          )
        )
    )
  );
}
