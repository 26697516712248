import { IImportExportState } from 'src/app/models/import.export';
import {
  ImportExportActions,
  ImportExportActionTypes,
} from '../actions/import.export.actions';

const initialState: IImportExportState = {
  exportData: null,
  uploadUrl: null,
  errorMessage: null,
  req_id: null,
  uploadStatus: null,
  isImportExportInProgress: null
};

export function importExportRecducer(
  state: IImportExportState = initialState,
  action: ImportExportActions
) {
  switch (action.type) {
    case ImportExportActionTypes.ExportFile:
      return {
        ...state,
        exportData: null,
        errorMessage: null,
        isImportExportInProgress: true
      };
    case ImportExportActionTypes.ExportFileSuccess:
      return {
        ...state,
        exportData: action.payload,
        isImportExportInProgress: false
      };
    case ImportExportActionTypes.ExportFileFailed:
      return {
        ...state,
        exportData: null,
        isImportExportInProgress: false,
        errorMessage: action.payload.message ? action.payload.message : 'Failed to export the file',
      };
    case ImportExportActionTypes.FetchUploadUrl:
      return {
        ...state,
        uploadUrl: null,
        errorMessage: null,
        uploadStatus: null,
        exportData: null,
        req_id: null,
        isImportExportInProgress: true
      };
    case ImportExportActionTypes.FetchUploadUrlSuccess:
      return {
        ...state,
        uploadUrl: action.payload,
      };
    case ImportExportActionTypes.FetchUploadUrlFailed:
      return {
        ...state,
        uploadUrl: null,
        isImportExportInProgress: false,
        errorMessage: action.payload.message ? action.payload.message : 'Failed to get upload url',
      };
    case ImportExportActionTypes.UploadFile:
      return {
        ...state,
        errorMessage: null
      };
    case ImportExportActionTypes.UploadFileSuccess:
      return {
        ...state,
      };
    case ImportExportActionTypes.UploadFileFailed:
      return {
        ...state,
        isImportExportInProgress: false,
        errorMessage: action.payload.message ? action.payload.message : 'Failed to upload file',
      };
    case ImportExportActionTypes.ImportData:
      return {
        ...state,
        errorMessage: null
      };
    case ImportExportActionTypes.ImportDataSuccess:
      return {
        ...state,
        req_id: action.payload.req_id,
        isImportExportInProgress: false
      };
    case ImportExportActionTypes.ImportDataFailed:
      return {
        ...state,
        isImportExportInProgress: false,
        errorMessage: action.payload.message ? action.payload.message : 'Failed to import data',
      };
    case ImportExportActionTypes.FetchUploadStatus:
      return {
        ...state,
      };
    case ImportExportActionTypes.FetchUploadStatusSuccess:
      return {
        ...state,
        uploadStatus: action.payload.status
      };
    case ImportExportActionTypes.FetchUploadStatusFailed:
      return {
        ...state,
        errorMessage: action.payload.message ? action.payload.message : 'Failed to fetch import status'
      };
    default:
      return state;
  }
}
